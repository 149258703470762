import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const Card = ({ isActive, setIsActive, setValue }) => {
  return (
    <>
      {isActive ? (
        <ul className="rounded-[18px] select-none absolute w-full left-0 top-12 flex flex-col gap-y-2 bg-[#141419] px-6 py-4">
          <li
            onClick={() => {
              setIsActive(false);
              setValue("Price Low to High");
            }}
            className="hover:opacity-50"
          >
            Price Low to High
          </li>
          <li
            onClick={() => {
              setIsActive(false);
              setValue("Price High to Low");
            }}
            className="hover:opacity-50"
          >
            Price High to Low
          </li>
        </ul>
      ) : null}
    </>
  );
};
const NftDropdown = ({ isActive, setIsActive, value, setValue }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  return (
    <div className="flex gap-x-1 text-[14px] relative items-center border border-[#999999] bg-[#141419] rounded-[18px] cursor-pointer sm:pl-5 pl-3 sm:pr-10 pr-3 sm:py-2 py-[6px]">
      <span>{value}</span>
      <IoMdArrowDropdown
        className={`text-xl ${
          isDropdownActive && "rotate-180"
        } transition-all duration-150 ease`}
        onClick={() => setIsDropdownActive((x) => !x)}
      />
      <Card
        isActive={isDropdownActive}
        setValue={setValue}
        setIsActive={setIsDropdownActive}
      />
    </div>
  );
};

export default NftDropdown;
