import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../services/axios";
import { useWallet } from "./useWallet";

const fetchReferredList = async (address) => {
  const res = await axios.get(
    `${baseURL}/api/user/refer?address=${address.toLowerCase()}`
  );
  return res;
};
export const useReferredList = () => {
  const wallet = useWallet();
  const { data, error, isLoading } = useQuery({
    queryKey: ["referredList", wallet?.address],
    queryFn: () => fetchReferredList(wallet?.address),
    enabled: !!wallet.address,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { data, error, isLoading };
};
