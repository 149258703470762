import React from "react";
import "../../pages/index.css";
import Tilt from "react-parallax-tilt";
const ReferInfoCard = ({ title, logoURI, text, index }) => {
  return (
    <Tilt
      className="lg:w-[30%] w-[32%] 2xl:h-[280px] md:h-[250px] sm:h-[200px] h-[140px]"
      tiltMaxAngleX={20}
      tiltMaxAngleY={20}
      perspective={800}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div
        style={{
          boxShadow: "0 0 50px rgba(254,124,4,0.8)",
        }}
        className="border group relative h-full w-full md:border-[#666666] border-[#999999] bg-[#0b0b0b] 2xl:rounded-[40px] md:rounded-[28px] sm:rounded-3xl rounded-2xl 2xl:px-12 lg:px-8 sm:px-5 px-3 2xl:py-8 lg:py-6 py-5"
      >
        <h1 className="xl:text-xl md:text-[14px] sm:text-[11px] text-[10px] w-full xl:text-left text-center font-bold font-akira uppercase">
          {title}
        </h1>
        <p className="sm:text-xs text-[8px] w-full leading-[1.1] xl:text-left text-center text-[#a9a9a9] font-redHat">
          {text}
        </p>
        <div
          className={`h-full w-full ${
            index === 2 ? "sm:-mt-8 -mt-6" : "sm:-mt-4 -mt-3"
          }  lg:scale-110 flex items-center justify-center`}
        >
          <img
            src={logoURI}
            className="h-fit w-fit sm:scale-100 scale-[.50] refer-earn-card  inner-element"
          />
        </div>
      </div>
    </Tilt>
  );
};

export default ReferInfoCard;
