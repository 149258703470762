import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import memory from "../assets/memory_match.png";
import "../pages/games/index.css";
import spin from ".././assets/spin_win.png";
import jackpot from "../assets/jackpot_.png";
import slidingPuzzle from "../assets/puzzle.png";
import wordSearch from "../assets/word_search.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css";
SwiperCore.use([Navigation]);
import GameCard from "../components/games/GameCard";
import CarouselArrows from "../components/games/CarouselArrows";
const games = [
  {
    image: memory,
    text: "Memory Matching",
    subtext: "Sharpens you memory with the matching game.",
    link: "/games/memory",
  },
  {
    image: spin,
    text: "Spin & Win",
    subtext: "Sharpens you memory with the matching game.",
    link: "/games/spin",
  },
  {
    image: wordSearch,
    text: "Word Search",
    subtext: "Sharpens you memory with the matching game.",
    link: "/games/word-search",
  },
  {
    image: slidingPuzzle,
    text: "Puzzle",
    subtext: "Sharpens you memory with the matching game.",
    link: "/games/sliding-puzzle",
  },
  {
    image: jackpot,
    text: "Jackpot",
    subtext: "Sharpens you memory with the matching game.",
    link: "/games/slot-machine",
  },
];
const GamingMarketplace = () => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  return (
    <div className="min-h-screen text-white  sm:pt-28 pt-24 w-full">
      <div className="lg:container relative md:text-left text-center w-full mx-auto ">
        <h1 className="lg:text-6xl px-8 w-full sm:text-4xl text-3xl font-bold font-akira">
          Play & Earn
        </h1>
        <p className="font-redHat px-8  mt-3 text-2xl">
          Play Game & Earn Blazpoints And Rewards
        </p>
        <div className="w-full lg:px-0 relative lg:container  mx-auto 0 2xl:mt-8 xl:mt-6 mt-10">
          <Swiper
            ref={swiperRef}
            slidesPerView={3}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            modules={[EffectCards, Navigation, Pagination]}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
            }}
            onSlideChange={(swiper) =>
              console.log("Slide index changed:", swiper.activeIndex)
            }
          >
            {games.map((game, index) => (
              <SwiperSlide key={index}>
                <div className="flex pb-4 justify-center">
                  <GameCard
                    logoURI={game.image}
                    name={game.text}
                    handleClick={() => navigate(game.link)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <CarouselArrows />
        </div>
      </div>
    </div>
  );
};

export default GamingMarketplace;
