import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useNavigate } from "react-router-dom";
import CardLayout from "../../components/layout/CardLayout";
import EntrypassCard from "../../components/profile/EntrypassCard";
import taikoImg from "../../assets/taikoEntryPass.png";
import mantaImg from "../../assets/mantaEntryPass.png";
import polygonImg from "../../assets/polygonEP.png";
import bnbImg from "../../assets/galxeEP.png";
import axios from "axios";
import MainEntrypassCard from "../../components/profile/MainEntrypassCard";
import EntrypassDetailsCard from "../../components/profile/EntrypassDetailsCard";
import { RiArrowLeftDoubleLine, RiArrowRightDoubleLine } from "react-icons/ri";
const setOfEntrypasses = [
  {
    imgPath: mantaImg,
    name: "Blazpay x manta Network",
    chainId: 169,
    redirect: "manta",
  },
  {
    imgPath: bnbImg,
    name: "Binance Entry Pass",
    chainId: 56,
    redirect: "binance",
  },
  {
    imgPath: taikoImg,
    name: "Movement Entry Pass",
    chainId: 167008,
    redirect: "movement",
  },
  {
    imgPath: polygonImg,
    name: "Polygon Entry Pass",
    chainId: 137,
    redirect: "polygon",
  },
];
const Entrypasses = () => {
  const [entrypasses, setEntrypasses] = useState(0);
  const [nfts, setNfts] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, speed: 10 });
  const [currentSlide, setCurrentSlide] = useState(0);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setCurrentSlide(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  React.useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    nextArrow: <RiArrowRightDoubleLine className="text-white" />,
    prevArrow: <RiArrowLeftDoubleLine />,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const getNfts = async () => {
      const res = await axios.get("/api/nft/all-balance");
      if (res) {
        setNfts(res);
      }
    };
    getNfts();
  }, []);
  function getBalance(chainId) {
    if (!nfts) {
      return 0;
    }
    const balance1 = nfts?.nftBalances[167008]?.balance || 0;
    const balance2 = nfts?.nftBalances[167009]?.balance || 0;
    const combinedBalance = balance1 + balance2;
    if (chainId === 167008 || chainId === 167009) {
      return combinedBalance;
    }
    return nfts?.nftBalances[chainId]?.balance || 0;
  }

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <CardLayout
      border={false}
      className="2xl:h-[740px] sm:border-[0.5px]  xl:mt-0 sm:mt-10 relative xl:h-[680px] sm:h-[860px] h-[1130px] xl:px-[60px] md:px-8 sm:px-6 px-4 pt-8 w-full rounded-[30px]"
      handleBack={handleBack}
      title={"My Entrypasses"}
    >
      <div className="2xl:h-[580px] xl:h-[500px] relative sm:h-[720px] h-[1020px]  w-full xl:pr-0 lg:px-8 xl:pl-6 my-8 w-fit flex sm:flex-row flex-col gap-x-1 justify-between">
        <MainEntrypassCard logoURI={taikoImg} />
        <div className="w-full  h-full gap-y-4 2xl:w-[800px] xl:w-[700px] lg:w-[600px] w-[400px]  flex flex-col">
          <div
            className="w-full xl:relative absolute bottom-0 left-0 sm:p-8 px-0 pb-8 sm:border  border-[#666666]
          rounded-3xl "
          >
            <div
              className="embla h-full  overflow-hidden 2xl:max-w-[740px] xl:max-w-[700px] max-w-[900px]"
              ref={emblaRef}
            >
              <div className="embla__container  flex">
                {setOfEntrypasses?.map((entrypass, index) => (
                  <div
                    className="embla__slide  h-full flex justify-center items-center md:flex-[0_0_33.333%] sm:flex-[0_0_45%] flex-[0_0_100%]"
                    key={index}
                  >
                    <EntrypassCard
                      logoURI={entrypass?.imgPath}
                      type={"Testnet"}
                      name={entrypass?.name}
                      balance={getBalance(entrypass?.chainId)}
                      link={entrypass?.redirect}
                    />
                  </div>
                ))}
              </div>
              {/* arrows */}
              <button
                className="absolute 2xl:bottom-3 sm:bottom-2 bottom-1 2xl:left-[320px] xl:left-[300px] left-[30vw]  z-10"
                onClick={scrollPrev}
              >
                <RiArrowLeftDoubleLine className="text-white" />
              </button>
              <button
                className="absolute 2xl:bottom-3 sm:bottom-2 bottom-1 2xl:right-[320px] xl:right-[300px] right-[30vw] z-10"
                onClick={scrollNext}
              >
                <RiArrowRightDoubleLine className="text-white" />
              </button>
            </div>
          </div>
          <div className="xl:h-full  h-fit border border-[#666666] rounded-3xl w-full xl:mt-0 md:mt-24 sm:mt-20 mt-6 lg:px-12 md:px-7 px-5 xl:py-4 lg:py-8 md:py-6 py-5">
            <h1 className="md:text-2xl  sm:text-xl text-base font-medium font-redHat">
              Details
            </h1>
            <EntrypassDetailsCard
              title={"Number of Mainnet Entrypass"}
              value={nfts?.mainnetBalance || 0}
              className={"mt-2"}
            />
            <EntrypassDetailsCard
              title={"Number of Testnet Entrypass"}
              value={nfts?.testnetBalance || 0}
              className="mt-2"
            />
            <EntrypassDetailsCard
              title={"Multiplier as per calculation"}
              value={nfts?.multiplier || 0}
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </CardLayout>
  );
};

export default Entrypasses;
