import { useEffect, useState } from "react";
import CartButton from ".././components/nft/CartButton";
import NftDropdown from ".././components/nft/NftDropdown";
import { addToCart, getCart, getProducts } from "../api/product";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "../hooks/useWallet";
import NftCard from "../components/nft/NftCard";
import Cart from "./Cart";
import ClientPaginationCard from "../components/common/ClientPaginationCard";
import DotLoader from "../components/loaders/DotLoader";
const Store = () => {
  const wallet = useWallet();
  const [cartItemCount, setcartItemCount] = useState(0);
  const [isActiveCart, setIsActiveCart] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredNfts, setFilteredNfts] = useState([]);
  const [filterValue, setFilterValue] = useState("Price Low to High");
  const [nfts, setNfts] = useState([]);
  const itemsPerPage = 12;

  const fetchCart = async (chainId) => {
    try {
      let cartData = await getCart({ chainId });
      cartData = cartData?.items.filter((item) => item.quantity >= 1);
      setcartItemCount(cartData?.length);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCart(wallet?.chainId);
  }, [wallet, isActiveCart]);

  const product = useQuery({
    queryKey: ["products", wallet?.chainId],
    queryFn: () => getProducts({ chainId: wallet?.chainId }),
    enabled: !!wallet?.chainId,
  });
  useEffect(() => {
    if (product?.data?.length > 0 && !product?.isLoading) {
      setTotalPages(Math.ceil(product?.data?.length / itemsPerPage));
      setNfts(product?.data);
      setFilteredNfts(
        product?.data?.sort((item1, item2) => item1?.price - item2?.price)
      );
    }
  }, [product?.data]);

  const handleAddMore = async (productId) => {
    try {
      await addToCart({
        productId,
        chainId: wallet?.chainId,
        quantity: 1,
        showToast: true,
      });
      fetchCart(wallet?.chainId);
      return true;
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };
  const handleBuyNow = async (productId) => {
    const res = await handleAddMore(productId);
    if (res) setIsActiveCart(true);
  };
  const handleNextPage = () => {
    if (totalPages > 1) {
      setCurrentPage((x) => x + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage >= 2) {
      setCurrentPage((x) => x - 1);
    }
  };

  const handleFilter = (value) => {
    setFilterValue(value);
    if (value === "Price Low to High") {
      setFilteredNfts(
        [...nfts]?.sort((item1, item2) => item1.price - item2.price)
      );
    } else {
      setFilteredNfts(
        [...nfts]?.sort((item1, item2) => item2.price - item1.price)
      );
    }
  };
  return (
    <div className="min-h-screen relative 2xl:pb-0 pb-4 xl:px-5 px-8 font-redHat w-full 2xl:pt-28 sm:pt-24 pt-20">
      <div className="container mx-auto">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col w-full md:items-start items-center">
            <h1 className="lg:text-6xl md:text-left text-center  w-full sm:text-4xl text-3xl font-bold font-akira">
              NFT Market Place
            </h1>
            <p className="lg:text-2xl md:text-xl sm:text-base text-[14px] md:text-left text-center w-full mt-2">
              Buy NFT & Earn Blazpoint
            </p>
          </div>
          <div className="flex md:-mt-5  mt-5 z-50 md:justify-end sm:justify-center justify-between w-full md:px-16   items-center sm:gap-x-6">
            <NftDropdown value={filterValue} setValue={handleFilter} />
            <CartButton
              itemCount={cartItemCount}
              handleClick={() => setIsActiveCart(true)}
              setValue={() => {}}
            />
          </div>
          <div className=" relative mx-auto mt-4 w-full xl:px-4 lg:px-16 md:px-12 sm:px-8 grid xl:gap-x-8 md:gap-x-9 gap-x-4 2xl:gap-y-5 xl:gap-y-2 gap-y-4 xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 ">
            <DotLoader
              isActive={product?.isLoading}
              className="h-60 absolute top-24 left-1/2 -translate-x-1/2"
            />
            {filteredNfts
              ?.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              ?.map((product, index) => {
                return (
                  <NftCard
                    key={index}
                    index={index}
                    logoURI={product.img}
                    title={product.title}
                    price={product.price}
                    className={""}
                    handleClick={() => handleAddMore(product._id)}
                    handleBuyNow={() => handleBuyNow(product._id)}
                  />
                );
              })}
          </div>
          <div className="w-full mt-2 flex items-center justify-center">
            <ClientPaginationCard
              isActive={true}
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextClick={handleNextPage}
              handlePrevClick={handlePrevPage}
            />
          </div>
        </div>
      </div>
      <Cart
        handleClick={handleAddMore}
        isActive={isActiveCart}
        setIsActive={setIsActiveCart}
      />
    </div>
  );
};

export default Store;
