import { useQuery } from "@tanstack/react-query";
import { getDailyTasks } from "../api/misc";
import { useWallet } from "./useWallet";

export const useDailyTask = () => {
  const wallet = useWallet();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["dailyTask", wallet?.chainId],
    queryFn: () => getDailyTasks(),
    enabled: !!wallet?.chainId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return { data, isLoading, isError };
};
