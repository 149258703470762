import { useQuery } from "@tanstack/react-query";
import { getCalculator } from "../api/misc";
import { useWallet } from "./useWallet";

export const useCalculator = () => {
  const wallet = useWallet();
  const { isLoading, data, isError } = useQuery({
    queryKey: ["calculator", wallet?.address],
    queryFn: () => getCalculator(),
    enabled: !!wallet?.address,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  console.log("data is this", data);

  return { data, isLoading, isError };
};
