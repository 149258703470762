import React from "react";

const PriceWrapper = ({ totalPrice, usdPrice }) => {
  return (
    <div className="flex w-full justify-between text-xl font-bold font-redHat items-start mt-2">
      <h1 className="sm:text-xl text-base">Total Price</h1>
      <div className="flex flex-col items-center">
        <h1 className="sm:text-xl text-base">{totalPrice} BLZ</h1>
        <h1 className="  text-[#999999]">${usdPrice}</h1>
      </div>
    </div>
  );
};

export default PriceWrapper;
