import React from "react";

const PurchaseButton = ({ handleClick, text, isLoading }) => {
  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      className={`w-fit 2xl:mt-12 mt-6 2xl:mb-10 mb-6 rounded-full font-bold bg-radial-play text-white px-8 py-2 font-redHat sm:text-base text-[14px] h-fit transition duration-300 ease-in-out `}
    >
      {text}
    </button>
  );
};
export default PurchaseButton;
