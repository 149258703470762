import React from "react";

const ReferEarnHistoryCard = ({ date, username, pointsEarned }) => {
  const formatDate = (date) => {
    const newDate = new Date(date);
    return `${newDate.getMonth()}-${newDate.getDay()}-${newDate.getFullYear()}`;
  };
  return (
    <div className="flex justify-between items-center font-redHat font-light py-3 leading-[1.2] sm:text-base text-[10px] rounded-full border border-[rgba(255,255,255,0.16)]  2xl:px-[50px] md:px-10 sm:px-6 px-2">
      <h2 className="w-1/3 ">{formatDate(date)}</h2>
      <h2 className="w-1/3 text-center relative -left-10">{username}</h2>
      <h2 className="w-1/3 text-end">{pointsEarned} BLZ</h2>
    </div>
  );
};

export default ReferEarnHistoryCard;
