import React from "react";

const ProfileButton = ({
  isLoading,
  handleSubmit,
  text,
  className,
  module = "create",
  disabled,
}) => {
  return (
    <button
      disabled={disabled || isLoading}
      onClick={handleSubmit}
      type="submit"
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      className={`${className} hover:opacity-75 ${
        !disabled && !isLoading && "active:scale-[.95]"
      } transition-all duration-[200ms] ease rounded-full ${
        module === "create"
          ? "md:text-xl sm:text-[18px] text-[14px] 2xl:px-28 lg:px-24 px-20 2xl:py-4 lg:py-3 py-2"
          : "2xl:px-28 xl:px-20 md:px-16 px-12 2xl:py-4 xl:py-3 md:py-[10px] py-2 lg:text-xl sm:text-base text-[13px]"
      } w-fit mt-4 font-bold bg-radial-play text-white  font-redHat  h-fit`}
    >
      {text}
    </button>
  );
};

export default ProfileButton;
