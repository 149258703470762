import React from "react";
import "./index.css";
const DotLoader = ({ isLoading, className }) => {
  return (
    <>
      {isLoading ? (
        <div className={`flex justify-center items-center ${className} `}>
          <div className="loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DotLoader;
