import React from "react";

const ReferButton = ({ title }) => {
  return (
    <button
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      className={`2xl:mt-8 mt-1 rounded-full font-bold bg-radial-play text-white px-16 py-2 font-redHat text-base h-fit transition duration-300 ease-in-out`}
    >
      {title}
    </button>
  );
};

export default ReferButton;
