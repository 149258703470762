import React from "react";

const FilledButton = ({
  handleClick,
  className,
  text,
  value,
  isLoading,
  loadintText,
  disabled,
  module,
}) => {
  return (
    <button
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      onClick={handleClick}
      className={` ${className} rounded-full cursor-pointer ${
        module === "game" && !isLoading && "hover:opacity-75 active:scale-[.95]"
      }
       
      ${module === "game" && disabled && "opacity-60 "}
      font-bold bg-radial-play text-white sm:px-16 px-12  lg:py-3 py-2 font-redHat  sm:text-base text-xs h-fit transition duration-300 ease-in-out`}
      disabled={isLoading}
    >
      {isLoading ? loadintText : <> {text}</>}
    </button>
  );
};

export default FilledButton;
