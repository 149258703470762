import React from "react";
import LinkButton from "../../components/dashboard/LinkButton";
const PlayEarnCard = ({ logoURI, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className="w-full lg:h-1/2 h-full group cursor-pointer relative rounded-3xl border border-[#666666]  py-4 flex justify-center items-center  bg-gradient-to-b from-[#e7583e] to-[#d43b2a]"
    >
      <img
        src={logoURI}
        alt=""
        className="h-[100%] w-fit group-hover:scale-[.95] transition-all duration-[400ms] ease-in-out"
      />
      <div
        style={{ backdropFilter: "blur(10px)" }}
        className="w-[90%] flex flex-col md:px-6 sm:px-4 px-2 md:py-6 sm:py-4 py-2 leading-[1.0] md:rounded-3xl sm:rounded-2xl rounded-xl bg-[rgba(0,0,0,0.4)] absolute left-1/2 -translate-x-1/2 bottom-5"
      >
        <div className="flex md:justify-start justify-between items-center md:gap-x-3 sm:gap-x-2 gap-x-1">
          <h1 className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl  sm:text-base text-[14px] font-akira font-bold">
            Play & Earn
          </h1>
          <LinkButton />
        </div>
        <span className="uppercase font-redHat md:text-[13px] sm:text-[11px] text-[9px] font-bold mt-1">
          Blaz points
        </span>
      </div>
    </div>
  );
};

export default PlayEarnCard;
