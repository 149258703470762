import React, { useState, useEffect } from "react";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { handleTransactionError } from "../../services/util";
import { getStakingInfo } from "../../api/misc";
import { parseEther } from "ethers/lib/utils";
import axios from "axios";
import { apiCall } from "../../services/ApiCall";
import taikoStone from "../../assets/taikoStone.gif";
import configs from "../../config/configs.json";
import MintButton from "../../components/dashboard/MintButton";
import StakeButton from "../../components/dashboard/StakeButton";
const MainCard = () => {
  const [totalRewards, setTotalRewards] = useState(0);
  const [stakedNFTsCount, setStakedNFTsCount] = useState(null);
  const [boughtNFTCount, setBoughtNFTCount] = useState(0);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [nftCountPerDay, setNftCountPerDay] = useState(0);

  const wallet = useWallet();
  const auth = useAuth();

  const data =
    wallet.chainId === 1890 || wallet.chainId === 1891
      ? [
          { title: "NFT Minted", value: boughtNFTCount, id: 1 },
          { title: "Rewards Earned", value: totalRewards, id: 3 },
        ]
      : [
          { title: "NFT Minted", value: boughtNFTCount, id: 1 },
          { title: "NFT Stake", value: stakedNFTsCount, id: 2 },
          { title: "Rewards Earned", value: totalRewards, id: 3 },
        ];

  const init = async () => {
    const config = wallet.getChainConfig();
    if (!config) return null;

    console.log(wallet.chainId);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    }
    const [, balance] = await wallet.safeCallContract({
      name: "NFT",
      method: "balanceOf",
      args: [wallet.address],
    });
    let balance2;
    if (wallet.chainId === 997) {
      balance2 = 0;
    } else {
      [, balance2] = await wallet.safeCallContract({
        name: "STAKE_OLD",
        method: "balanceOf",
        args: [wallet.address],
      });
    }

    if (balance || balance2) {
      setBoughtNFTCount(balance?.toNumber() + balance2?.toNumber());
    }

    if (config.features.apiStaking) {
      const response = await getStakingInfo();
      setTotalRewards(response?.totalRewards);
      setStakedNFTsCount(response?.stakeCount);
    } else {
      const [, stakedNfts1] = await wallet.safeCallContract({
        name: "Stake",
        method: "getStakedNFTs",
        args: [wallet.address],
      });

      let stakedNfts2;
      if (wallet.chainId === 997) {
        stakedNfts2 = [];
      } else {
        const [, stakedNfts2] = await wallet.safeCallContract({
          name: "STAKE_OLD",
          method: "getStakedNFTs",
          args: [wallet.address],
        });
      }
      setStakedNFTsCount(
        (stakedNfts1?.length || 0) + (stakedNfts2?.length || 0)
      );

      const [, rewards] = await wallet.safeCallContract({
        name: "Stake",
        method: "calculateTotalRewards",
        args: [wallet.address],
      });

      let rewardsOlder;
      if (wallet.chainId === 997) {
        rewardsOlder = 0;
      } else {
        const [, rewardsOlder] = await wallet.safeCallContract({
          name: "STAKE_OLD",
          method: "calculateTotalRewards",
          args: [wallet.address],
        });
        if (rewards || rewardsOlder) {
          setTotalRewards((Number(rewards) + Number(rewardsOlder)).toString());
        }
      }
    }
  };
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "MINTNFT",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        setNftCountPerDay(data.data?.processCount);
        setBoughtNFTCount(data.data?.processCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    const updatedNftCount = nftCountPerDay + 1;

    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "MINTNFT",
          date: new Date(),
          count: updatedNftCount,
        },
        "POST"
      );
      if (isSuccess) {
        getActivityCount();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintNFT = async () => {
    const config = wallet.getChainConfig();
    if (!config) return;

    setIsMintLoading(true);

    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      try {
        const { isSuccess, data } = await apiCall(
          `${configs.POST_LOGIN_API_URL}mint/nft`,
          {},
          {
            userAddress: wallet.address,
          },
          "POST"
        );
        if (isSuccess) {
          setBoughtNFTCount((prev) => prev + 1);
          updateActivityCount();
        }
      } catch (error) {
        console.error(error);
        alert("Minting failed via API");
      }
    } else {
      const transactionOptions = {
        value: parseEther(String(config.features.mintValue)),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "safeMint",
        args: [wallet.address, transactionOptions],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Minting...",
          success: () => {
            setBoughtNFTCount((prev) => prev + 1);
            return "NFT Minted successfully! It can take 15 seconds to show up in your wallet for staking.";
          },
          error: (err) => {
            console.error(err);
            return "Minting failed";
          },
        });
      }
    }

    setIsMintLoading(false);
  };

  const stakeNFT = async () => {
    if (data[0]?.value === 0) {
      toast.error("First mint an NFT and then stake");
      return;
    }
    const config = wallet.getChainConfig();
    if (!config) return;
    console.log(wallet.chainId);
    if (
      ["167008", "17000"].includes(wallet.chainId.toString()) &&
      +stakedNFTsCount >= 20
    ) {
      return toast.info(
        `You've staked the maximum number of NFTs allowable on this testnet`
      );
    }

    setIsStakeLoading(true);

    const [err1, ownedNFTs] = await wallet.safeCallContract({
      name: "NFT",
      method: "tokensOfOwner",
      args: [wallet.address],
    });
    console.log("ownedNFTs", ownedNFTs);
    if (err1) return setIsStakeLoading(false);

    if (ownedNFTs?.length === 0) {
      toast.info("No NFTs owned to stake.");
      setIsStakeLoading(false);
      return;
    }

    const tokenIdToStake = ownedNFTs[0];

    if (config.features.apiStaking) {
      const [err2, isApproved] = await wallet.safeCallContract({
        name: "NFT",
        method: "isApprovedForAll",
        args: [wallet.address, stakingAddress],
      });

      if (err2) return setIsStakeLoading(false);

      if (isApproved === false) {
        const [err3, approveTx] = await wallet.safeCallContract({
          name: "NFT",
          method: "setApprovalForAll",
          args: [stakingAddress, true],
        });

        if (err3) return setIsStakeLoading(false);

        await approveTx.wait();
      }

      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "sendToken",
        args: [
          tokenIdToStake,
          config.contracts.Stake.address,
          {
            value: parseEther(String(config.features.stakeValue)),
          },
        ],
      });

      if (tx) {
        try {
          await tx.wait();

          toast.success(
            `Transaction successful. The NFT will be staked in a few minutes in Holesky. Please don't attempt staking again for 5 minutes.`,
            {
              autoClose: 10000,
            }
          );

          stakeNow(tokenIdToStake.toNumber())
            .then(() => {
              setStakedNFTsCount((prev) => prev + 1);
              setBoughtNFTCount((prev) => prev - 1);
              toast.success(
                `NFT (ID: ${tokenIdToStake.toNumber()}) Staked Successfully`
              );
            })
            .catch((err) => {
              console.error(err);
              toast.error("API failed, please contact support");
            });
        } catch (err) {
          handleTransactionError(err);
        }
      }
    } else {
      console.log("HERE IS THE LOG");
      const [err2, isApproved] = await wallet.safeCallContract({
        name: "NFT",
        method: "getApproved",
        args: [tokenIdToStake],
      });
      console.log("🚀 ~ stakeNFT ~ err2:", err2, isApproved);

      if (err2) return setIsStakeLoading(false);

      if (isApproved === false) {
        const [err3, approveTx] = await wallet.safeCallContract({
          name: "NFT",
          method: "approve",
          args: [config.contracts.NFT.address, tokenIdToStake],
        });

        if (err3) return setIsStakeLoading(false);

        await approveTx.wait();
      }
      const transactionOptions = {
        value: parseEther(String(config.features.stakeValue)),
        ...(config.features.gasLimit && { gasLimit: "600000" }),
      };
      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "stakeNFT",
        args: [tokenIdToStake, transactionOptions],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Staking...",
          success: () => {
            setStakedNFTsCount((prev) => prev + 1);
            setBoughtNFTCount((prev) => prev - 1);
            return "Staked Successfully";
          },
          error: (err) => {
            console.error(err);
            return "Stake Failed";
          },
        });
      }
    }

    setIsStakeLoading(false);
  };

  const claimKatla = async () => {
    setIsClaimLoading(true);
    // if (wallet.chainId && wallet.chainId == "167009") {
    console.log("hekla");
    try {
      const address = auth.user.loginAddress;
      console.log(address);
      const res = await axios.post("/api/nft/claim-katla", { address });
      setIsClaimLoading(false);
      if (res.status === 200) {
        await auth.updateTokens();
        return toast.success("Claim Successful");
      } else {
        return toast.error("Something went wrongg");
      }
    } catch (error) {
      console.log(error);
      setIsClaimLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    }
  }, []);
  console.log("res nft", data);
  return (
    <div className='bg-gradient-to-r overflow-hidden from-[#FF7A03] to-[#FF3603] relative lg:w-[70%] w-full md:min-h-[440px] sm:min-h-[240px] min-h-[210px] md:max-h-[640px] sm:max-h-[280px] max-h-[200px] xl:h-[56vh] h-[40vh]   lg:rounded-[30px] md:rounded-3xl sm:rounded-2xl rounded-[12px] lg:pl-12 md:pl-10 sm:pl-8 pl-6 flex items-center justify-between'>
      <div className='flex flex-col items-start'>
        <h1 className='2xl:text-[56px] xl:text-5xl md:text-4xl sm:text-3xl tex-xl font-bold font-akira'>
          BlazStone NFT
        </h1>
        <div className='flex md:mt-6 sm:mt-4 mt-3 leading-[1.15] items-center md:gap-x-7 sm:gap-x-4 gap-x-2'>
          <MintButton
            text={
              isMintLoading
                ? "Processing..."
                : (wallet.chainId === 30732 &&
                    boughtNFTCount + stakedNFTsCount >= 5) ||
                  ((wallet.chainId === 1890 || wallet.chainId === 1891) &&
                    nftCountPerDay >= 5)
                ? "5 max NFTs"
                : "Mint"
            }
            handleClick={mintNFT}
            isLoading={
              isMintLoading ||
              ((wallet.chainId === 1890 || wallet.chainId === 1891) &&
                nftCountPerDay >= 5) ||
              (wallet.chainId === 30732 &&
                boughtNFTCount + stakedNFTsCount >= 5)
            }
          />
          <StakeButton
            handleClick={stakeNFT}
            text={isStakeLoading ? "Processing..." : "Stake"}
            isLoading={stakedNFTsCount && isStakeLoading}
            disabled={data[0]?.value === 0}
          />
        </div>
        <div className='flex xl:mt-7 lg:mt-4 md:mt-7 mt-4 items-center lg:gap-x-4 md:gap-x-3 sm:gap-x-2 gap-x-[5px]'>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className='xl:text-[14px] lg:text-xs md:text-[13px] sm:text-[10px] text-[7px] py-1 font-redHat rounded-full xl:px-6 lg:px-4 md:px-6 sm:px-3 px-2 md:border-[3px] sm:border-2 border-[1.5px] border-[rgba(255,255,255,0.3)]'
              >
                {item.title} : {item.value}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className='absolute  top-1/2 -translate-y-1/2 2xl:w-[480px] xl:w-[440px] md:w-[400px] w-[200px] 2xl:-right-12 md:-right-20 sm:right-0  -right-8'
        style={{ perspective: "1000px" }}
      >
        <img src={taikoStone} alt='' />
      </div>
    </div>
  );
};
export default MainCard;
