import React from "react";
import BottomCard from "./BottomCard";
import Tilt from "react-parallax-tilt";
const HomeDailyQuestCard = ({ title, className, logoURI }) => {
  return (
    <Tilt
      className={`${className} bg-black group relative flex flex-col items-center lg:justify-start
     justify-center lg:shadow-[0_0_30px_rgba(216,182,110,0.9)] shadow-[0_0_40px_rgba(255,81,0,0.7)]
      w-full xl:px-7 px-5 xl:py-6 py-4 lg:rounded-[22px] rounded-3xl  md:border-[0.5px] border-[1px]`}
      tiltMaxAngleX={20}
      tiltMaxAngleY={20}
      perspective={1000}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div>
        <h1 className="lg:text-base lg:block hidden text-center font-akira uppercase font-bold">
          {title}
        </h1>
        <img
          src={logoURI}
          className="h-fit w-fit 2xl:scale-110 xl:scale-100 lg:scale-[.80] scale-[1.2] 2xl:mt-8 lg:mt-0  group-hover:scale-90 transtion-all duration-[250ms] ease"
          alt={logoURI}
        />
        <BottomCard title={title} />
      </div>
    </Tilt>
  );
};

export default HomeDailyQuestCard;
