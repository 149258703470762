import PropTypes from "prop-types";
import Navbar from "./Navbar";
import bgImage from "../../assets/bg_dots.svg";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import CreateProfile from "../CreateProfile";

export default function Layout() {
  const auth = useAuth();
  return (
    <section
      className="w-full min-h-full "
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
      }}
    >
      <Navbar />
      {auth.user.isProfileCreated ? <Outlet /> : <CreateProfile />}
    </section>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};
