import React from "react";
import dailyTaskLogo from ".././assets/daily_task_logo.svg";
import qaLogo from ".././assets/qa_logo.svg";
import playEarnLogo from ".././assets/Play_earn_logo.svg";
import { useNavigate } from "react-router-dom";
import PlayEarnCard from "../components/dashboard/PlayEarnCard";
import DailyTaskCard from "../components/dashboard/DailyTaskCard";
import MainCard from "../components/dashboard/MainCard";
import LeaderboardCard from "../components/dashboard/LeaderboardCard";
import NftMarketCard from "../components/dashboard/NftMarketCard";
import QuestionCard from "../components/dashboard/QuestionCard";
import { useCalculator } from "../hooks/useCalculator";
import { useReferredList } from "../hooks/useReferredList";
import { useQuestions } from "../hooks/useQuestions";
import { useDailyTask } from "../hooks/useDailyTask";
import { useLeaderboard } from "../hooks/useLeaderboard";
import { useReferralList } from "../hooks/useReferralList";
const Dashboard = () => {
  const navigate = useNavigate();
  const { calculatorData } = useCalculator();
  const { refereeedList } = useReferredList();
  const { questions } = useQuestions();
  const { dailyTasks } = useDailyTask();
  const { referralList } = useReferralList(1);
  const leaderboard = useLeaderboard(1, 10, null, null, {
    imgPath: "",
    chainName: "All",
    chainId: "",
  });
  return (
    <div className="w-full 2xl:pb-0 pb-4 md:pt-24 pt-20 min-h-screen">
      <div className="xl:container w-full xl:px-4  px-5 mx-auto">
        <div className="flex lg:flex-row flex-col lg:gap-y-0 md:gap-y-6 sm:gap-y-5 gap-y-4 w-full  lg:min-h-[440px] md:min-h-[760px] sm:min-h-[460px] min-h-[380px] lg:max-h-[640px] md:max-h-[800px] sm:max-h-[480px] max-h-[400px]  xl:h-[56vh] lg:h-[40vh] h-[60vh]  gap-x-6">
          <MainCard />
          <div className="flex lg:w-[30%] w-full lg:gap-y-4 lg:gap-x-0 md:gap-x-4 gap-x-3 lg:min-h-[440px] md:min-h-[280px] sm:min-h-[200px] min-h-[150px]  lg:max-h-[640px] md:max-h-[320px] sm:max-h-[220px] max-h-[160px] xl:h-[56vh] lg:h-[40vh] md:h-[20vh]   lg:flex-col flex-row">
            <PlayEarnCard
              logoURI={playEarnLogo}
              handleClick={() => navigate("/gaming-marketplace")}
            />
            <DailyTaskCard
              text={"earn blaz points"}
              title={"daily task"}
              logoURI={dailyTaskLogo}
              handleClick={() => navigate("/daily-tasks")}
            />
          </div>
        </div>
        <div className="flex lg:flex-nowrap justify-between flex-wrap w-full  2xl:min-h-[300px] xl:min-h-[220px]  lg:min-h-[240px] md:min-h-[560px] sm:min-h-[520px] min-h-[380px]  xl:h-[28vh] md:h-[40vh] lg:max-h-[260px] max-h-[400px] lg:mt-4 md:mt-3 sm:mt-4 lg:gap-x-6">
          <LeaderboardCard
            title={"Leaderboard"}
            handleClick={() => navigate("/leaderboard")}
            leaderboard={leaderboard}
          />
          <NftMarketCard handleClick={() => navigate("/store")} />
          <QuestionCard
            text={"earn blaz points"}
            title={"q & a"}
            logoURI={qaLogo}
            handleClick={() => navigate("/questions")}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
