import React from "react";
import LinkButton from "./LinkButton";

const DailyTaskCard = ({ title, text, handleClick, logoURI }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        boxShadow: "0 0 50px rgba(254,124,4,1)",
      }}
      className="w-full bg-[#0b0b0b] cursor-pointer group md:px-8 sm:px-6 px-5 md:py-7 py-4 sm:py-3 py-[10px] relative border-[#666666] lg:h-1/2 h-full rounded-3xl"
    >
      <LinkButton
        className={"absolute 2xl:right-10 2xl:top-10 right-5 top-4"}
      />
      <div className="flex flex-col items-start">
        <h1 className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl  sm:text-base text-[14px] font-akira font-bold">
          {title}
        </h1>
        <p className="text-xs uppercase font-redHat">{text}</p>
      </div>
      <img
        src={logoURI}
        style={{
          filter: "drop-shadow(-20px -20px 20px rgba(255, 216, 142, 1))",
        }}
        alt=""
        className="absolute xl:right-5 lg:right-1 md:right-5 sm:right-3 -right-2 lg:-bottom-4 md:-bottom-0 sm:-bottom-4 -bottom-9 h-fit w-fit xl:scale-[.85] lg:scale-[.75] md:scale-100 sm:scale-[.75] scale-[.55]  group-hover:scale-[.75] transition-all duration-[400ms] ease-in-out"
      />
    </div>
  );
};

export default DailyTaskCard;
