import React, { useState, useEffect } from "react";
import MainCard from "../components/dailyTasks/MainCard";
import UserLogos from "../components/dailyTasks/UserLogos";
import SocialCard from "../components/dailyTasks/SocialCard";
import TimerCard from "../components/dailyTasks/TimerCard";
import DotLoader from "../components/loaders/DotLoader";
import { useWallet } from "../hooks/useWallet";
import "./index.css";
import { useDailyTask } from "../hooks/useDailyTask";
import comingSoonLogoURI from "../assets/comingSoonLogo.png";
const DailyTasks = () => {
  const [timer, setTimer] = useState([
    { label: "days", count: 0 },
    { label: "hrs", count: 0 },
    { label: "mins", count: 0 },
    { label: "sec", count: 0 },
  ]);
  const [activeTask, setActiveTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const { wallet } = useWallet();
  const { data, isLoading, isError } = useDailyTask();
  const calculateTimeLeft = () => {
    const now = new Date();
    const currentUTC = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    const targetUTC = new Date();
    targetUTC.setUTCHours(23, 59, 59, 999);

    const timeDiff = targetUTC - currentUTC;

    if (timeDiff <= 0) {
      return { days: 0, hrs: 0, mins: 0, sec: 0 };
    }

    const sec = Math.floor((timeDiff / 1000) % 60);
    const mins = Math.floor((timeDiff / 1000 / 60) % 60);
    const hrs = Math.floor((timeDiff / 1000 / 60 / 60) % 24);
    const days = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
    return { days, hrs, mins, sec };
  };

  useEffect(() => {
    const updateTimer = () => {
      const { days, hrs, mins, sec } = calculateTimeLeft();
      setTimer([
        { label: "days", count: days },
        { label: "hrs", count: hrs },
        { label: "mins", count: mins },
        { label: "sec", count: sec },
      ]);
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading && data?.tasks?.length > 0) {
      setTasks(data?.tasks);
    }
  }, [data]);

  return (
    <div className="min-h-screen font-redHat w-full 2xl:pb-0 pb-4 2xl:px-5 xl:px-8 px-5 2xl:pt-28 sm:pt-24 flex items-center justify-center pt-20">
      {/* coming soon */}
      <div className="flex flex-col items-center gap-y-2">
        <img
          src={comingSoonLogoURI}
          alt=""
          className="lg:h-40 md:h-32 h-28 z-0 coming-soon-logo  object-cover"
        />
        <h1
          style={{
            textShadow: "0 0 40px rgba(255,255,255,1)",
          }}
          className="md:text-6xl sm:text-5xl text-4xl text-center z-10 w-full relative -top-20 text-[#eee] font-bold font-akira"
        >
          Coming Soon
        </h1>
      </div>
      {/* <div className="lg:container w-full mx-auto">
        <MainCard />
        <div className="flex   w-full  mt-3 justify-end items-center">
          <UserLogos count={12300} />
        </div>
        <div className="flex xl:-mt-2 md:mt-6 mt-16 flex-col w-full">
          <div className="flex md:flex-row flex-col md:pl-0 sm:pl-6 md:items-center items-start relative top-1 md:gap-x-2">
            <h1 className="md:text-2xl sm:text-4xl text-2xl font-bold">
              Tasks
            </h1>
            <p className="md:text-[14px] sm:text-xl text-xs text-[#666666]">
              0/50 BLZ Points
            </p>
          </div>
          <div className="flex relative w-full md:mt-4 mt-24 xl:gap-x-12 lg:gap-x-8 gap-x-6 items-start">
            <div className="flex lg:w-[66%] md:w-[70%] relative w-full flex-col xl:gap-y-2 gap-y-4">
              <DotLoader
                isLoading={isLoading}
                className={"w-full flex items-center justify-center h-60 "}
              />
              {tasks?.slice(0, 5)?.map((social) => {
                return (
                  <SocialCard
                    key={social?._id}
                    icon={social?.imageUrl}
                    title={social?.title}
                    points={social?.points}
                    id={social?._id}
                    activeId={activeTask}
                    desc={social?.description}
                    link={social?.redirectUrl}
                    handleClick={() =>
                      setActiveTask((currentTask) =>
                        currentTask === social?._id ? null : social?._id
                      )
                    }
                  />
                );
              })}
            </div>
            <TimerCard
              className={
                "md:relative absolute md:top-0 md:right-0 sm:right-6 right-0 sm:-top-52 -top-44"
              }
              timer={timer}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DailyTasks;
