import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { IoIosWarning } from "react-icons/io";
import { useWallet } from "../hooks/useWallet";
import { handleTransactionError, toastPromise } from "../services/util";
import CustomRewardCard from "../components/questions/CustomRewardCard";
import QuestionCard from "../components/questions/QuestionCard";
import { useAuth } from "../hooks/useAuth";
import { parseEther } from "ethers/lib/utils";
import { getSignature } from "../api/games";
import { apiCall } from "../services/ApiCall";
import configs from "../config/configs.json";
import CustomButton from "../components/buttons/CustomButton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DotLoader from "../components/loaders/DotLoader";
import { useQuestions } from "../hooks/useQuestions";
import Completed from "../components/questions/Completed";
const Questions = () => {
  const [answers, setAnswers] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [markedIndices, setMarkedIndices] = useState([1]);
  const [tokens, setTokens] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState(null);
  const [isFetchingContract, setIsFetchingContract] = useState(false);
  const [isSubmittng, setIsSubmitting] = useState(false);
  const [count, setCount] = useState(false);
  const wallet = useWallet();
  const auth = useAuth();
  const { data, isLoading, isError } = useQuestions();
  useEffect(() => {
    setAnswers([]);
    setQuestionIndex(0);
    setOptionIndex(null);
  }, [wallet.chainId]);

  const question = useMemo(() => {
    return data?.[questionIndex];
  }, [questionIndex, data]);

  const submitAnswers = async (answers) => {
    const config = wallet.getChainConfig();
    if (!config) return;

    const message = "Quiz";
    const nonce = 147;

    const signature = await getSignature({
      to: wallet.address,
      amount: ethers.utils.parseEther("0"),
      message: message,
      nonce: nonce,
      chainId: wallet.chainId,
    });

    const rightAnswerCount = answers.filter((item) => item.isRight).length;
    const multiplier = localStorage.getItem("multiplier") || 1;

    const transactionOptions = {
      value: parseEther(String(config.features.spinValue * 10)),
      ...(config.features.gasLimit && { gasLimit: "900000" }),
    };

    const [err, tx] = await wallet?.safeCallContract({
      name: "Game",
      method: "bulkAnswer",
      args: [
        rightAnswerCount,
        multiplier,
        message,
        nonce,
        signature,
        transactionOptions,
      ],
    });

    if (err) return;

    await toastPromise(tx.wait(), {
      pending: "Submitting...",
      success: () => {
        const tokens = rightAnswerCount * 50 * multiplier;
        setIsCompleted(true);
        setTokens(tokens);
        wallet.updatePoints({
          amount: tokens,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "questions",
          txHash: tx.hash,
        });
        auth.updateTokens();
        return `Successfully added ${tokens} tokens`;
      },
      error: (err) => {
        handleTransactionError(err);
      },
    });
  };

  const handleNextQuestion = async () => {
    if (optionIndex == null) {
      return toast.error("Please choose an option");
    }
    setMarkedIndices((prev) => {
      const lastElement = prev[prev.length - 1];
      return [...prev, lastElement + 1];
    });
    const option = question.options[optionIndex];
    const finalAnswers = answers
      .filter((item) => item.questionId !== question._id)
      .concat([
        {
          questionId: question._id,
          optionId: option._id,
          isRight: !!option.blockchainNumber,
        },
      ]);
    setAnswers(finalAnswers);

    if (isLastQuestion) {
      setIsSubmitting(true);
      try {
        if (wallet.chainId === 1890 || wallet.chainId === 1891) {
          updateRewardCount(finalAnswers);
        } else {
          await submitAnswers(finalAnswers);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setQuestionIndex((index) => index + 1);
      setOptionIndex(null);
    }
  };

  const isLastQuestion = data?.length === questionIndex + 1;
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "QUIZQUESTION",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setCount(count);
        setIsCompleted(count === 1 ? true : false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (tokens) => {
    // const rightAnswerCount = answers.filter((item) => item.isRight).length;
    // const multiplier = localStorage.getItem("multiplier") || 1;
    // const tokens = rightAnswerCount * 50 * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(tokens),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(tokens);
        // updateActivityCount();
        setIsCompleted(true);
        setTokens(tokens);
        wallet.updatePoints({
          amount: tokens,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "questions",
          txHash: "mintReward",
        });
        getActivityCount();
        auth.updateTokens();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async (answers) => {
    const rightAnswerCount = answers.filter((item) => item.isRight).length;
    const multiplier = localStorage.getItem("multiplier") || 1;
    const tokens = rightAnswerCount * 50 * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: tokens,
          gameType: "QUIZQUESTION",
          date: new Date(),
          count: 1,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(tokens);
        getRewardCount();
        auth.updateTokens();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "QUIZQUESTION" },
        "GET"
      );
      if (isSuccess) {
        setTokens(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "QUIZQUESTION",
          date: new Date(),
          count: 1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!wallet?.address) return;

    const init = async () => {
      if (wallet?.chainId === 1890 || wallet?.chainId === 1891) {
        getActivityCount();
      } else {
        setIsFetchingContract(true);

        const [, isCompleted] = await wallet?.safeCallContract({
          name: "Game",
          method: "hasUserAnsweredToday",
          args: [wallet?.address],
        });

        const [, tokens] = await wallet.safeCallContract({
          name: "Game",
          method: "getAnswerRewards",
          args: [wallet?.address],
        });

        if (isCompleted) {
          setIsCompleted(true);
        }

        if (tokens) {
          setTokens(
            Number(
              ethers.utils.formatUnits(tokens.toString()).toString()
            ).toFixed(0)
          );
        }
      }
    };
    init()
      .catch(console.error)
      .finally(() => {
        setIsFetchingContract(false);
      });
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    if (wallet?.chainId === 1890 || wallet?.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, [wallet.address, wallet.chainId]);
  useEffect(() => {
    setMarkedIndices((prev) => [...prev]);
  }, []);
  console.log("res", isLoading, isCompleted);
  return (
    <>
      {/* {isFetchingContract && isCompleted === false && (
        <div className="min-h-screen w-full flex items-center justify-center">
          <DotLoader
            isLoading={isFetchingContract}
            className={"h-60  absolute left-1/2 -translate-x-1/2 top-12 "}
          />
        </div>
      )} */}
      {isCompleted ? (
        <Completed tokens={tokens} />
      ) : (
        <>
          <div className="min-h-screen 2xl:pb-0 pb-4 2xl:pt-28 sm:pt-24 pt-20 w-full ">
            <div className="container xl:px-5 sm:px-8 px-5  mx-auto">
              <div className="flex md:flex-row flex-col xl:justify-start md:justify-between md:items-start items-center">
                <div className="flex md:w-1/2 w-full md:text-left text-center flex-col items-start">
                  <h1 className="lg:text-6xl  w-full sm:text-4xl text-3xl font-bold font-akira">
                    Q & A
                  </h1>
                  <p className="lg:text-2xl w-full md:text-xl sm:text-base text-xs lg:mt-3 sm:mt-2 mt-1">
                    Give the right answer and get the Blazpoints
                  </p>
                </div>
                <div className="xl:w-1/2 w-fit md:mt-0 mt-6">
                  <CustomRewardCard text="Rewards Earned" value={tokens} />
                </div>
              </div>
              <div className="w-full relative flex xl:flex-row flex-col xl:gap-x-10 xl:mt-20 mt-12">
                <DotLoader
                  isLoading={isLoading}
                  className={"h-60  absolute left-1/2 -translate-x-1/2 top-12 "}
                />
                {!isLoading && (
                  <>
                    <div
                      style={{
                        boxShadow: "0 0 50px rgba(255,124,3,1)",
                      }}
                      className="xl:w-2/5 w-full border border-[#666666] md:text-2xl sm:text-xl text-base font-akira  2xl:py-16 xl:py-12 md:py-16 sm:py-10 py-8 2xl:px-12 xl:px-8 md:px-16 sm:px-12 px-8 rounded-[40px] bg-[#010101]"
                    >
                      {question?.questionText}
                    </div>
                    <div className="xl:w-3/5 xl:mt-0 sm:mt-8 mt-6 w-full items-center flex flex-col 2xl:gap-y-8 xl:gap-y-5 md:gap-y-6 sm:gap-y-5 gap-y-3">
                      {question?.options?.map((question, index) => {
                        return (
                          <QuestionCard
                            key={index}
                            handleClick={() => setOptionIndex(index)}
                            count={index + 1}
                            answer={question?.text}
                            optionIndex={optionIndex}
                          />
                        );
                      })}
                      <div className="flex gap-x-2 w-full sm:-mt-2  pl-12 items-center">
                        <IoIosWarning className="text-2xl text-[#ff3503]" />
                        <span className="2xl:text-base text-[14px]">
                          Do not refresh or go back on the page, as your saved
                          answers will be lost.
                        </span>
                      </div>
                      {isLastQuestion && (
                        <CustomButton
                          handleClick={handleNextQuestion}
                          text={"Submit"}
                          loadintText={"Submitting..."}
                          isLoading={false}
                          className={"text-base px-16 py-3 w-fit"}
                        />
                      )}
                      {!isLastQuestion && (
                        <div className="flex w-full text-3xl md:justify-end justify-center items-center">
                          <IoIosArrowBack className="text-[#666666]" />
                          <div className="flex gap-x-1 items-center">
                            {markedIndices?.map((item, index) => {
                              return (
                                <span
                                  key={index}
                                  className={` ${
                                    item ===
                                    markedIndices[markedIndices.length - 1]
                                      ? "text-[#ff3503"
                                      : "text-white"
                                  }] text-base`}
                                >
                                  {item}
                                </span>
                              );
                            })}
                          </div>

                          <div
                            onClick={handleNextQuestion}
                            className="flex gap-x-1 items-center cursor-pointer transition-all duration-150 ease active:scale-[0.95]"
                          >
                            <IoIosArrowForward />
                            <span className="text-base select-none">
                              Next Page
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Questions;
