import React from "react";
import BottomCard from "./BottomCard";
import Tilt from "react-parallax-tilt";
const PlayEarnCard = ({ title, className, logoURI }) => {
  return (
    <Tilt
      className={`${className} bg-gradient-to-b relative from-[#FF5500] to-[#000000]
     group relative flex flex-col lg:justify-start justify-center overflow-hidden 
     items-center lg:shadow-[0_0_30px_rgba(243,181,61,1)] shadow-[0_0_40px_rgba(255,81,0,0.7)] 
     w-full px-7 py-6 lg:rounded-[22px] rounded-3xl md:border-[0.5px] border-[1px]`}
      tiltMaxAngleX={20}
      tiltMaxAngleY={20}
      perspective={800}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div>
        <h1 className="lg:text-base lg:block hidden text-center font-akira uppercase font-bold">
          {title}
        </h1>
        <img
          src={logoURI}
          className="h-full lg:scale-[1.3] md:scale-[1.1] sm:scale-[1.2] scale-[1.3] mt-0  w-full lg:p-2 group-hover:scale-110 transtion-all duration-[250ms] ease"
        />
        <BottomCard title={title} />
      </div>
    </Tilt>
  );
};

export default PlayEarnCard;
