import { useQuery } from "@tanstack/react-query";
import { getLeaderboardReferrals } from "../api/misc";

export const useReferralList = ({ currentPage }) => {
  const {
    data: referralData,
    isLoading: referralIsLoading,
    isError: referralIsError,
  } = useQuery({
    queryKey: ["referral", currentPage],
    queryFn: () => getLeaderboardReferrals(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return { referralData, referralIsLoading, referralIsError };
};
