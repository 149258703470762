import GoldTrophy from "../../assets/gold_trophy.png";
import SilverTrophy from "../../assets/silver_trophy.png";
import BronzeTrophy from "../../assets/bronze_trophy.png";
import WinnerCard from "./WinnerCard";
const WinnerWrapper = ({ top3, isLoading }) => {
  return (
    <div
      className={`flex md:w-1/2 w-full ${
        isLoading ? "opacity-0 scale-[.80]" : "scale-100 opacity-100"
      } relative xl:top-0 md:top-6 md:mt-0 sm:mt-8 mt-6 flex-col items-center transition-all duration-[140ms] ease`}
    >
      <h1 className="sm:text-2xl text-xl font-akira">Daily</h1>
      <div className="flex items-end">
        <WinnerCard
          winner={top3[1]?.username}
          imgSrc={SilverTrophy}
          className={`relative   -top-2 z-10 transition-all duration-[800ms] ease ${
            isLoading ? "left-24" : "left-4"
          }`}
          _bottom={12}
          _fontSize={12}
        />
        <WinnerCard
          winner={top3[0]?.username}
          imgSrc={GoldTrophy}
          className="z-20"
          _bottom={16}
          _shadow={true}
        />
        <WinnerCard
          winner={top3[2]?.username}
          imgSrc={BronzeTrophy}
          className={`relative  -top-2 z-10 transition-all duration-[1200ms] ease ${
            isLoading ? "-left-28" : "-left-4"
          }`}
          _bottom={12}
          _fontSize={12}
        />
      </div>
    </div>
  );
};

export default WinnerWrapper;
