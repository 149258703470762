import React from "react";
import CustomButton from "./CustomButton";
import ReferButton from "./ReferButton";
import ReferralCard from "./ReferralCard";

const ReferButtonsContainer = ({
  referralCode,
  handleCopy,
  handleHistory,
  totalReferred,
  className,
  isCopied,
}) => {
  return (
    <div className={`${className} flex flex-col md:items-start items-center`}>
      <ReferButton title={"Invite Friend"} />
      <ReferralCard
        code={referralCode}
        className="mt-4"
        handleCopy={handleCopy}
        isCopied={isCopied}
      />
      <div className="xl:mt-8 md:mt-16 mt-8 gap-x-6 flex items-center">
        <CustomButton text={"Total Referred"} value={totalReferred} />
        <CustomButton text={"History"} handleHistory={handleHistory} />
      </div>
    </div>
  );
};

export default ReferButtonsContainer;
