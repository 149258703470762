import { useWallet } from "../hooks/useWallet";
import { addToCart, completePurchase, getCart } from "../api/product";
import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { handleTransactionError, toastPromise } from "../services/util";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { apiCall } from "../services/ApiCall";
import configs from "../config/configs.json";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoInformationCircleOutline } from "react-icons/io5";
import PurchaseButton from "../components/nft/PurchaseButton";
import PriceWrapper from "../components/nft/PriceWrapper";
import NftCartWrapper from "../components/nft/NftCartWrapper";
import { clearCart } from "../api/misc";
const Cart = ({ isActive, setIsActive, handleClick }) => {
  const [cart, setCart] = useState({
    isLoading: false,
    isError: false,
    data: null,
    error: null,
  });
  const [isPurchasing, setIsPurchasing] = useState(false);
  const wallet = useWallet();
  const auth = useAuth();

  const chain = wallet.chainId && wallet.getChainConfig();

  const totalPrice = useMemo(() => {
    return (
      cart.data?.items.reduce(
        (price, item) => (price += item.productPrice * item.quantity),
        0
      ) || 0
    );
  }, [cart.data]);

  const handleQuantityChange = async (productId, quantity) => {
    setCart((cart) => {
      const items = cart?.data?.items;
      const index = items.findIndex((e) => e.productId === productId);
      items[index].quantity += quantity;

      if (items[index].quantity <= 0) {
        delete items[index];
      }

      return {
        ...cart,
        data: { ...cart.data, items: items.filter(Boolean) },
      };
    });

    await addToCart({
      productId,
      quantity,
      chainId: wallet.chainId,
    });
  };

  const fetchCart = async (chainId) => {
    try {
      const cartData = await getCart({ chainId });
      setCart({
        isLoading: false,
        isError: false,
        data: {
          ...cartData,
          items: cartData?.items?.filter((item) => item.quantity >= 1),
        },
        error: null,
      });
    } catch (err) {
      console.error(err);
      setCart({
        isLoading: false,
        isError: true,
        error: err,
      });
    }
  };
  const handleClearCart = async () => {
    setCart((cart) => ({
      ...cart,
      data: { ...cart.data, items: [] },
    }));
    toast.info("All items removed");
    const res = await clearCart(wallet?.chainId);
    await fetchCart(wallet?.chainId);
  };

  useEffect(() => {
    fetchCart(wallet?.chainId);
  }, [handleClick]);
  const purchaseNFT = async (totalPrize, nftItems) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/purchaseNFT`,
        {},
        {
          totalPrize,
          nftItems,
        },
        "POST"
      );
      if (isSuccess) {
        setCart((prev) => ({
          ...prev,
          data: {
            items: [],
          },
        }));
        completePurchase({
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          txHash: "lightlinknftpurchase",
          amountRedeemed: totalPrice,
        });
        auth.updateTokens();
        setIsPurchasing(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const purchase = async () => {
    const chain = wallet.getChainConfig();
    if (!chain) return;

    if (cart.data.items.length === 0) {
      return toast.info("Please add some products to your cart");
    }

    if (+auth.tokens < totalPrice) {
      return toast.error("Insufficient tokens");
    }
    setIsPurchasing(true);
    if (wallet?.chainId === 1890 || wallet?.chainId === 1891) {
      purchaseNFT(totalPrice, cart.data.items);
    } else {
      const weiPrice = ethers.utils.parseEther(totalPrice.toString());
      const [err1, tokenAllowance] = await wallet?.safeCallContract({
        name: "Token2",
        method: "allowance",
        args: [wallet.address, chain.contracts.Game.address],
      });

      if (err1) return setIsPurchasing(false);

      if (tokenAllowance && tokenAllowance.lt(weiPrice)) {
        const [err2, approveTx] = await wallet.safeCallContract({
          name: "Token2",
          method: "approve",
          args: [chain.contracts.Game.address, weiPrice],
        });

        if (err2) return setIsPurchasing(false);

        await toastPromise(approveTx.wait(), {
          pending: "Approving...",
          success: "Approved",
          error: "Approve Failed",
        });
      }

      const tokens = cart?.data?.items?.map((item) => item?.productMintUrl);
      const [, tx] = await wallet?.safeCallContract({
        name: "Game",
        method: "batchRedeemTokens",
        args: [
          tokens,
          weiPrice,
          {
            gasLimit: 1000000,
          },
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Purchasing...",
          success: () => {
            setCart((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                items: [],
              },
            }));
            completePurchase({
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              txHash: "purchaselightlinknft",
              amountRedeemed: totalPrice,
            });
            auth.updateTokens();
            return "Purchase Successful";
          },
          error: (err) => {
            handleTransactionError(err);
          },
        });
      }

      setIsPurchasing(false);
    }
  };

  useEffect(() => {
    setCart({
      isLoading: true,
      isError: false,
      data: null,
      error: null,
    });

    fetchCart(wallet.chainId);
  }, [wallet.chainId]);

  if (cart?.isError) return <div>Error: {cart.error?.message}</div>;

  return (
    <>
      {isActive ? (
        <div
          style={{ backdropFilter: "blur(3px)" }}
          className="min-h-screen sm:px-0 px-5 w-full fixed top-0 left-0 z-50  bg-[rgba(0,0,0,0.5)]"
        >
          <div className="absolute top-1/2 -translate-y-1/2  xl:right-8 md:right-20 right-1/2 md:translate-x-0 translate-x-1/2 border-[3px] px-10 py-5 bg-[#010101] rounded-[36px] flex items-center flex-col 2xl:w-[500px] sm:w-[400px] w-[360px]">
            <div className="flex  w-full justify-between items-center">
              <div className="flex gap-x-1 items-center uppercase font-bold font-akira text-base">
                <h1>your cart</h1>
                <IoInformationCircleOutline className="text-xl " />
              </div>
              <IoIosCloseCircleOutline
                onClick={() => setIsActive(false)}
                className="text-2xl cursor-pointer"
              />
            </div>
            <NftCartWrapper
              handleClearCart={handleClearCart}
              handleQuantityChange={handleQuantityChange}
              data={cart?.data?.items}
            />
            <PriceWrapper totalPrice={totalPrice} usdPrice={0} />
            <PurchaseButton
              isLoading={isPurchasing}
              handleClick={purchase}
              text={isPurchasing ? "Purchasing..." : "Complete Purchase"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Cart;
