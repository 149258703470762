import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { checkUsername, updateProfile } from "../api/user";
import ProfileLaytout from "./layout/ProfileLaytout";
import InputField from "./profile/InputField";
import ProfileButton from "./profile/ProfileButton";

const CreateProfile = () => {
  const [username, setUsername] = useState("");
  const [referralCode, setReferral] = useState("");
  const [loadingUsernameCheck, setLoadingUsernameCheck] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);
  const auth = useAuth();
  const handleReferralChange = (e) => setReferral(e.target.value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username) {
      try {
        setIsRegistering(true);
        const response = await updateProfile({
          username,
          referralCode,
        });

        auth.updateProfile({
          username: response.username,
          isProfileCreated: true,
        });

        toast.success("Profile created successfully");
      } catch (error) {
        console.error(error);
        toast.error(
          error.response?.data?.message || "Error during creating profile"
        );
      } finally {
        setIsRegistering(false);
      }
    } else {
      toast.info("Please enter a username.");
    }
  };
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (username) {
        try {
          setLoadingUsernameCheck(true);
          const response = await checkUsername(username);
          setUsernameAvailable(response.available);
          setLoadingUsernameCheck(false);
        } catch (error) {
          console.error("Error checking username availability:", error);
          setLoadingUsernameCheck(false);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [username]);
  useEffect(() => {
    if (username?.length > 15) {
      toast.error("Username should not be greater that 15 characters");
      return;
    }
  }, [username]);
  const handleChange = (e) => {
    setUsername(e.target.value);
    setUsernameAvailable(true);
  };
  return (
    <ProfileLaytout
      className={"lg:py-16 py-12 lg:px-28 md:px-20  sm:px-8 px-5"}
      title="Create Profile"
    >
      <div className="flex flex-col  items-center sm:mt-10  mt-16 mb-16 w-full sm:gap-y-6 gap-y-4">
        <InputField
          value={auth.user.loginAddress}
          placeholder="Wallet Address"
          disabled={true}
        />

        <div className="w-full relative">
          <InputField
            placeholder="Username"
            value={username}
            required={true}
            onChange={handleChange}
            className={`
               ${
                 username.length >= 3 &&
                 usernameAvailable &&
                 !loadingUsernameCheck &&
                 "border-green-500 focus:border-green-500"
               }`}
          />
          {username.length >= 3 && usernameAvailable && (
            <h1 className="font-akira md:text-base sm:text-xs text-[10px] text-white absolute top-1/2 -translate-y-1/2 right-7">
              @Blazpay
            </h1>
          )}
        </div>
        <InputField
          value={referralCode}
          onChange={handleReferralChange}
          id="referralCode"
          placeholder="Referral Code (Optional)"
        />
        <ProfileButton
          isLoading={isRegistering}
          handleSubmit={handleSubmit}
          text={"Submit"}
        />
      </div>
    </ProfileLaytout>
  );
};

export default CreateProfile;
