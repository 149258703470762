import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
const CustomDropdown = ({ items, setItem, className, setIsActiveDropdown }) => {
  return (
    <ul
      className={`${className} gap-y-1 lg:rounded-[18px] md:rounded-2xl rounded-[12px]  bg-[#141419] px-2 py-5
     flex flex-col items-start`}
    >
      {items.map((item) => {
        return (
          <li
            onClick={() => {
              setItem(item);
              setIsActiveDropdown(false);
            }}
            className="lg:px-12 px-8 hover:opacity-30 cursor-pointer"
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};
const DropdownButton = ({ category, setCatory, categories }) => {
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  return (
    <div
      style={{
        boxShadow: "0 0 20px rgba(255,255,255,0.85) inset",
      }}
      className="flex relative  rounded-full bg-[#ff3503] uppercase 2xl:px-20 lg:px-16 px-10 2xl:py-5 lg:py-3 py-2 font-bold  font-redHat flex-col items-center"
    >
      <div className="flex 0 cursor-pointer items-center gap-x-1">
        <h1 className="lg:text-[14px] md:text-[13px] text-[10px]">category</h1>
        <IoMdArrowDropdown
          onClick={() => setIsActiveDropdown((x) => !x)}
          className={`cursor-pointer md:text-2xl text-base ${
            isActiveDropdown && "rotate-180"
          } transition-all duration-[100ms] ease-in`}
        />
      </div>
      <h1 className="lg:text-xl md:text-base text-xs uppercase">{category}</h1>
      <CustomDropdown
        className={`absolute  lg:text-base md:text-[14px] text-xs transition-all duration-[200ms] ease-in-out 2xl:left-8 lg:left-4 md:left-2 left-1 ${
          isActiveDropdown
            ? "z-0 2xl:top-28 lg:top-24 md:top-[86px] top-[70px]  scale-100 "
            : "opacity-0 top-16 scale-[.45]"
        }`}
        items={categories}
        setItem={setCatory}
        setIsActiveDropdown={setIsActiveDropdown}
      />
    </div>
  );
};

export default DropdownButton;
