import React from "react";

const QuestionCard = ({ answer, handleClick, count, optionIndex }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        boxShadow: "0 9px 26px -17px #fff inset,0 0 10px 0 #a9a9a9 inset",
      }}
      className={`
           ${optionIndex === count - 1 ? "border-[#6DD0E7]" : "border-primary"}
          w-full hover:scale-[1.02] transition-all duration-[400ms] ease-in-out cursor-pointer bg-black flex items-center gap-x-4 group rounded-full border-[3px] 2xl:px-3 sm:px-2 px-1 2xl:py-3 sm:py-2 py-1`}
    >
      <div
        style={{
          boxShadow: "0 0 10px rgba(255,255,255,0.8) inset",
        }}
        className={`sm:h-[56px] h-10 sm:w-[56px] w-10 
            
             ${optionIndex === count - 1 ? "bg-[#6DD0E7]" : "bg-[#ff3503]"}
            text-xl font-akira flex items-center justify-center rounded-full`}
      >
        {count}
      </div>
      <h1 className="font-medium relative  left-0 group-hover:left-2 transition-all duration-[500ms] ease-in-out 2xl:text-2xl sm:text-xl text-base">
        {answer}
      </h1>
    </div>
  );
};

export default QuestionCard;
