import React from "react";
import CustomRewardCard from "./CustomRewardCard";
import CustomButton from "../buttons/CustomButton";
import completedQuestionsLogoURI from "../../assets/completedQuestions.png";
import { useNavigate } from "react-router-dom";
const Completed = ({ tokens }) => {
  const navigate = useNavigate();
  const handleReturnHome = () => {
    navigate("/dashboard");
  };
  return (
    <div className="min-h-screen 2xl:pb-0 pb-4 2xl:pt-28 sm:pt-24 pt-20 w-full">
      <div className="container  mx-auto  flex justify-between lg:items-center md:items-start items-center md:pt-0 pt-12 px-8">
        <div className="flex  flex-col md:items-start items-center">
          <h1 className="xl:text-6xl md:text-left text-center lg:text-5xl  md:text-4xl sm:text-2xl text-base font-bold font-akira">
            Congratulations all the Q & A are completed{" "}
          </h1>
          <h2 className="xl:text-3xl lg:text-2xl md:text-xl  font-medium text-[#ff3503]">
            Please come back tommorrow
          </h2>
          <CustomRewardCard
            className="lg:mt-8 mt-4"
            text="Rewards Earned"
            value={tokens}
          />
          <CustomButton
            text={"Return to home"}
            className={
              "sm:text-base text-[14px] lg:px-16 sm:px-12 px-8 sm:py-3 py-2 w-fit mt-6"
            }
            handleClick={handleReturnHome}
          />
        </div>

        <img
          src={completedQuestionsLogoURI}
          alt={"completed questions"}
          className="2xl:scale-100 lg:scale-[.60] scale-[.50]  relative xl:left-0 lg:-left-20 lg:top-0 -top-16 -left-32 md:block hidden"
        />
      </div>
    </div>
  );
};

export default Completed;
