import React from "react";

const RewardCard = ({
  labelLeft,
  labelRight,
  countLeft,
  countRight,
  className,
}) => {
  return (
    <div
      style={{
        boxShadow:
          "0 3px 12px -3px rgba(255,255,255,1) inset, 0 0 6px 0 #a9a9a9",
      }}
      className={`flex  font-redHat rounded-3xl bg-black px-8 py-3  justify-between items-center ${className}`}
    >
      <div className="flex border-r border-[#666666] 2xl:w-52 sm:w-36 w-32  flex-col items-center">
        <p className="2xl:text-base text-[13px] text-center w-24 leading-[1.0] font-redHat font-bold">
          {labelLeft}
        </p>
        <h1 className="text-[#ff3503] sm:text-2xl text-xl font-bold">
          {countLeft}
        </h1>
      </div>
      <div className="flex flex-col 2xl:w-52 sm:w-36 w-32 items-center">
        <p className="2xl:text-base text-[13px] text-center w-24 leading-[1.0] font-redHat font-bold">
          {labelRight}
        </p>
        <h1 className="text-[#ff3503] sm:text-2xl text-xl font-bold ">
          {countRight}
        </h1>
      </div>
    </div>
  );
};

export default RewardCard;
