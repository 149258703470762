import React from "react";

const CustomButton = ({
  text,
  isLoading,
  className,
  loadintText,
  handleClick,
}) => {
  return (
    <button
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      onClick={handleClick}
      className={` ${className}  rounded-full font-bold bg-radial-play text-white 2 font-redHat active:scale-[.96] h-fit transition-all duration-300 ease-in-out
     `}
      disabled={isLoading}
    >
      {isLoading ? loadintText : <> {text}</>}
    </button>
  );
};

export default CustomButton;
