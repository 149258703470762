import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import tutorialLogo from "../assets/tutorial.svg";
import frequentlyAskedLogo from "../assets/frequently_asked.png";
import earnLogo from "../assets/earn_forever.svg";
import ReferButtonsContainer from "../components/referEarn/ReferButtonsContainer";
import ReferInfoCard from "../components/referEarn/ReferInfoCard";
import { useReferredList } from "../hooks/useReferredList";
import referEarnLogo from "../assets/refer_logo.svg";
import Tilt from "react-parallax-tilt";
const referralCardData = [
  {
    title: "tutorial",
    text: "How does the referral program works?",
    logoURI: tutorialLogo,
  },
  {
    title: "frequently asked questions",
    logoURI: frequentlyAskedLogo,
  },
  {
    title: "earn forever",
    text: "Each time they trade, get 20% of their trading fee for an unlimited period of time.",
    logoURI: earnLogo,
  },
];
const Referral = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useReferredList();
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = async () => {
    if (isCopied) return;
    toast.info("Copied referral code");
    setIsCopied(true);
    await navigator.clipboard.writeText(data?.referralCode);
  };
  const handleHistory = () => {
    navigate("/refer/referral-history");
  };
  return (
    <div className="min-h-screen w-full md:pt-28 pt-24">
      <div className="xl:container w-full xl:px-5 px-8  mx-auto">
        <h1 className="lg:text-6xl md:text-left text-center w-full text-4xl font-bold font-akira">
          Refer & Earn
        </h1>
        <p className="lg:text-2xl md:text-left text-center text-xl font-redHat lg:mt-2 md:mt-4 mt-5 lg:w-[560px] md:w-[400px] w-full">
          Invite your friends and get access to special offers & reward points.
        </p>
        <div className="w-full xl:mt-0 md:mt-20  mt-8 relative  flex md:flex-row flex-col md:items-start items-center md:justify-between">
          <div className="flex md:order-1 order-2 flex-col md:items-start items-center ">
            <ReferButtonsContainer
              className={"mt-4"}
              referralCode={data?.referralCode}
              handleHistory={handleHistory}
              totalReferred={data?.totalReferred}
              handleCopy={handleCopy}
              isCopied={isCopied}
            />
          </div>
          <div className="md:absolute 2xl:-top-36 xl:-top-40 lg:-top-28 md:-top-36 2xl:right-0 xl:-right-8 right-0 2xl:h-[520px] xl:h-[440px] sm:h-[360px] 2xl:w-[600px] xl:w-[480px] sm:w-[400px] w-full w-1/2 md:order-2 order-1 ">
            <img src={referEarnLogo} className="h-full w-full" />
          </div>
        </div>
      </div>

      <div className="lg:container  w-full xl:px-0 md:px-12 px-5 mx-auto flex 2xl:mt-20 sm:mt-12 mt-8 flex-col items-center">
        <h1 className="font-bold font-akira lg:text-4xl md:text-3xl sm:text-2xl text-base">
          What Expanded Next
        </h1>
        <div className="w-full flex lg:justify-evenly justify-between 2xl:mt-12 xl:mt-2 md:mt-12 sm:mt-10 mt-4 items-center">
          {referralCardData.map((item, index) => {
            return (
              <ReferInfoCard
                key={index}
                index={index}
                title={item.title}
                logoURI={item.logoURI}
                text={item.text}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Referral;
