import React, { useEffect, useState } from "react";
const CustomCard = ({ handleClick, mask, logoURI, name }) => {
  return (
    <div
      onClick={() => handleClick()}
      className="flex cursor-pointer hover:opacity-75 justify-between items-center"
    >
      <div className="flex gap-x-[6px] items-center">
        <img src={logoURI} className="h-5 w-5 rounded-full" alt="" />
        <span className="text-xs">{name}</span>
      </div>
      <span className="text-[13px] text-[rgba(255,255,255,0.25)] font-bold">
        {mask}
      </span>
    </div>
  );
};
const ChainSelectDropdown = ({
  isActive,
  handleChainClick,
  className,
  data,
  setIsActive,
}) => {
  const [inputValue, setInputValue] = useState("");
  const filteredChains = data?.filter((item) =>
    item?.name?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase())
  );
  useEffect(() => {
    setInputValue("");
  }, [handleChainClick]);
  return (
    <div
      className={`${className} ${
        isActive ? "scale-100 opacity-100" : "scale-0 opacity-0"
      } transition-all duration-200 ease rounded-3xl md:w-[230px] w-[280px] border border-[#666666] md:px-5 px-6 bg-[#1E1F26] md:py-4 py-8 flex flex-col md:gap-y-0 gap-y-1 items-start`}
    >
      <h2 className="text-xs font-bold font-redHat">Select Your Chain</h2>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Search Chain"
        className="bg-transparent w-full outline-none rounded-full mt-2 border border-[#ff5500] text-xs py-1 px-4 placeholder:text-[rgba(255,255,255,0.25)]"
      />
      <div className="flex mt-4 flex-col w-full gap-y-2">
        {filteredChains?.map((item, index) => {
          return (
            <CustomCard
              key={index}
              name={item?.name}
              mask={item?.mask}
              logoURI={item?.tokenImg}
              handleClick={() => {
                handleChainClick(item);
                setIsActive(false);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChainSelectDropdown;
