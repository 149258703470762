import React from "react";

const ButtonCard = ({ text, value, className }) => {
  return (
    <div
      style={{
        boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
      }}
      className={`${className} flex items-center px-6 2xl:py-2 py-1 gap-x-2 bg-black rounded-full border-2 border-primary`}
    >
      <h1 className={`font-redHat uppercase sm:text-xl text-[14px]`}>{text}</h1>
      <span className="text-[#ff3503] font-bold  xl:text-xl text-base">
        {value}
      </span>
    </div>
  );
};

export default ButtonCard;
