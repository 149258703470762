import React from "react";
import cardBgLogo from "../../assets/stake_bg.svg";
import BottomCard from "./BottomCard";
import Tilt from "react-parallax-tilt";
const CommonCard = ({ title, className, logoURI, module }) => {
  return (
    <Tilt
      className={`${className} flex group  bg-black relative flex-col overflow-hidden items-center lg:justify-start justify-center lg:shadow-[0_0_30px_rgba(216,182,110,0.9)] shadow-[0_0_40px_rgba(255,81,0,0.7)] ${
        module === 0 && "shadow-[inset_4px_0_50px_rgba(216,182,110,0.2)]"
      } w-full xl:px-7 lg:px-5 px-3 xl:py-6 lg:py-4 lg:py-8 py-6  lg:rounded-[22px] rounded-3xl  md:border-[0.5px] border-[1px] `}
      tiltMaxAngleX={20}
      tiltMaxAngleY={20}
      perspective={1200}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div>
        <h1 className="lg:text-base lg:block hidden font-akira uppercase font-bold">
          {title}
        </h1>
        <img
          src={logoURI}
          className={`h-fit w-fit z-10 ${
            module === 1 ? "2xl:mt-20 lg:mt-8 " : "2xl:mt-10 mt-0 "
          } group-hover:scale-90 transtion-all 2xl:scale-[1.2] duration-[250ms] ease`}
          alt={logoURI}
        />
        {module === 1 && (
          <>
            <img
              src={cardBgLogo}
              className="absolute top-0 left-0 xl:scale-[1.1] scale-[1.2] h-full w-full z-[-99] "
            />
          </>
        )}
        <BottomCard title={title} />
      </div>
    </Tilt>
  );
};
export default CommonCard;
