import React from "react";
import { HiArrowUpRight } from "react-icons/hi2";
const LinkButton = ({ className, handleClick }) => {
  return (
    <div
      className={`md:h-6 sm:h-4 h-[14px] md:w-6 sm:w-4 w-[14px] ${className} rounded-full flex items-center justify-center bg-white`}
    >
      <HiArrowUpRight
        onClick={handleClick}
        className="md:text-base text-[8px] font-bold text-black"
      />
    </div>
  );
};
export default LinkButton;
