import React from "react";

const JackpotButtonCard = ({ text, value }) => {
  return (
    <div className="flex flex-col items-center gap-y-5">
      <div
        style={{
          boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
        }}
        className="2xl:px-12 px-4 2xl:py-4 sm:py-1 py-3 sm:w-fit w-40 bg-black rounded-full border-2 border-primary"
      >
        <h1 className="font-redHat uppercase 2xl:text-xl sm:text-base text-xs text-center">
          {text}
        </h1>
      </div>
      <div
        className={` ${
          value < 99999
            ? "2xl:w-[320px] md:w-72 sm:w-56 w-32   md:text-5xl  sm:text-4xl text-2xl"
            : "2xl:text-5xl md:text-4xl sm:text-2xl text-base px-10  w-fit"
        } border  2xl:py-20 lg:py-16 py-8  bg-black lg:rounded-[36px] md:rounded-[30px] rounded-3xl font-bold font-akira flex items-center justify-center`}
      >
        <h1>{value}</h1>
      </div>
    </div>
  );
};

export default JackpotButtonCard;
