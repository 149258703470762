import React from "react";
const ProfileLaytout = ({
  title,
  children,
  icon,
  className,
  backButton = false,
  module = "create",
}) => {
  return (
    <div className="min-h-screen flex justify-center items-center px-4 pb-4 w-full">
      <div className="container 2xl:px-44 xl:px-36 lg:px-12 sm:px-8 pt-20 mx-auto">
        <div
          className={` ${className} h-full w-full md:rounded-[30px] rounded-3xl bg-[#111111]  border `}
        >
          <div
            className={`${
              backButton ? "justify-start" : "justify-center"
            } flex xl:gap-x-32 lg:gap-x-24 md:gap-x-16 sm:gap-x-12 gap-x-4 items-center w-full`}
          >
            {icon}
            <h1
              className={`uppercase  ${
                module === "create"
                  ? "lg:text-5xl md:text-4xl sm:text-3xl text-xl"
                  : "2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-[14px]"
              }  font-akira font-bold bg-gradient-to-b from-[#fff] to-[#666666] bg-clip-text text-transparent`}
            >
              {title}
            </h1>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLaytout;
