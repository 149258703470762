import React from "react";

const EntrypassDetailsCard = ({ title, value, className }) => {
  return (
    <div
      className={`${className} cursor-pointer transition-all duration-[150ms] ease-in-out hover:border-[#afafaf] w-full sm:text-[15px] text-[13px] xl:py-3 py-[10px] px-5 rounded-full border border-[#666666] flex justify-between items-center`}
    >
      <h1>{title}</h1>
      <span className="text-[#999999]">{value}</span>
    </div>
  );
};

export default EntrypassDetailsCard;
