import React from "react";

const CustomButton = ({ text, value, handleHistory }) => {
  return (
    <div
      onClick={handleHistory}
      style={{
        boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
      }}
      className="flex items-center cursor-pointer md:px-10 px-6 font-bold md:py-3 py-2 gap-x-2 bg-black rounded-full border-2 border-primary"
    >
      <h1
        className={`font-redHat uppercase md:text-[14px] text-[11px] font-bold]`}
      >
        {text}
      </h1>
      <span className="font-bold md:text-base text-[11px]"> {value}</span>
    </div>
  );
};

export default CustomButton;
