import React from "react";
import solanaLogo from "../../assets/solana_logo.png";
const logoURIs = [
  "https://i.ibb.co/LvqnDrv/matic.png",
  "https://i.ibb.co/CtMkR8V/taiko.png",
  "https://i.ibb.co/QC0vv1P/bnb.png",
];
const TokenField = ({
  className,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  id,
  module,
}) => {
  return (
    <div
      className={`${className} w-full flex gap-x-0 items-center transition-all duration-250 rounded-3xl border border-[#666666]  sm:px-5 px-4 2xl:py-4 xl:py-3 py-2  text-base`}
    >
      {module === "solana" ? (
        <div className="w-fit sm:px-5 px-3">
          <img
            src={solanaLogo}
            alt={"solana logo"}
            className="sm:h-8 sm:w-9 h-6 w-7  rounded-full"
          />
        </div>
      ) : (
        <div className="flex w-fit">
          {logoURIs?.map((logo, index) => {
            return (
              <img
                style={{
                  left: `-${index * 12}px`,
                }}
                src={logo}
                alt={logo}
                className="sm:h-7 sm:w-7 h-5 w-5 rounded-full relative"
              />
            );
          })}
        </div>
      )}

      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className="w-full border-l border-[#666666] text-[#afafaf] bg-transparent outline-none xl:text-xl md:text-base sm:text-[14px] text-xs  sm:px-4 pl-3 pr-2"
        id={id}
      />
    </div>
  );
};
export default TokenField;
