import React from "react";

const MintButton = ({ handleClick, isLoading, text }) => {
  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      style={{
        boxShadow: "0 8px 24px -16px #fff inset, 0 0 6px 0 #a9a9a9",
      }}
      className={`md:text-[18px] sm:text-xs text-[10px]  transition-all duration-200 ease hover:scale-95 shadow-xl font-bold font-redHat rounded-full md:py-3 sm:py-2 py-[6px] xl:px-20 lg:px-16 md:px-20 sm:px-12 px-7 md:border-[3px] sm:border-2 border-[1.23px]
           ${isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"}}
          `}
    >
      {text}
    </button>
  );
};

export default MintButton;
