import React from "react";

const LeaderboardInfoCard = ({
  index,
  logoURI,
  username,
  pointsLogoURI,
  totalPoints,
}) => {
  return (
    <div className="flex items-center justify-between ">
      <div className="flex items-center md:gap-x-6 sm:gap-x-4 gap-x-2">
        <h1 className="md:text-2xl sm:text-xl text-xs font-akira">
          {index + 1}
        </h1>
        <img
          src={logoURI}
          className="sm:h-5 sm:w-5 h-3 w-3 rounded-full"
          alt=""
        />
        <p className="capitalize md:text-base sm:text-xs text-[10px] font-bold">
          {username}
        </p>
      </div>
      <div className="flex items-center md:gap-x-3 sm:gap-x-2 gap-x-1">
        <img
          src={pointsLogoURI}
          className="sm:scale-[.70] scale-[.50]"
          alt=""
        />
        <h2 className="sm:text-xs text-[10px]">{totalPoints.toFixed(0)}</h2>
      </div>
    </div>
  );
};

export default LeaderboardInfoCard;
