import React from "react";
import Loader from "../Loader/Loader";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../api/misc";
import LinkButton from "./LinkButton";
import dragonWarrior from "../../assets/dragon_Warrior.png";
import blazPointsLogoURI from "../../assets/blaz_points.png";
import LeaderboardInfoCard from "./LeaderboardInfoCard";
const LeaderboardCard = ({ leaderboard, className, handleClick, title }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        boxShadow: "0 0 50px rgba(254,124,4,1)",
      }}
      className={` ${className} lg:order-1 order-2  bg-[#0b0b0b] cursor-pointer relative md:px-8 sm:px-6 px-5 md:py-7 py-4 sm:py-3 py-[10px] lg:w-[40%] w-[calc(50%+8px)] border border-[#666666]  rounded-3xl lg:h-[100%] md:h-[50%] sm:h-[220px] h-[150px]`}
    >
      <LinkButton
        className={"absolute 2xl:right-10 2xl:top-10 right-5 top-4"}
      />
      <h1 className='2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl  sm:text-base text-[12px] font-akira font-bold'>
        {title}
      </h1>
      <div className=' w-full overflow-x-auto'>
        <div className='lg:mt-8  sm:w-full max-w-full min-w-[180px] md:mt-16 sm:mt-10 mt-4   flex flex-col 2xl:gap-y-3 gap-y-1'>
          {leaderboard.isLoading && (
            <div className='flex  flex-row w-full  h-[70%] align-middle justify-center items-center'>
              <Loader />
            </div>
          )}
          {leaderboard?.data?.leaderboard.slice(0, 3).map((user, index) => {
            return (
              <LeaderboardInfoCard
                key={index}
                index={index}
                username={user?.username}
                logoURI={dragonWarrior}
                pointsLogoURI={blazPointsLogoURI}
                totalPoints={user?.totalPoints}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardCard;
