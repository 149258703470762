import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
const NavAssetCard = ({ handleClick, logoURI, chain }) => {
  return (
    <div className="flex lg:gap-x-[5px] gap-x-[2px] items-center ">
      {chain ? (
        <img
          src={logoURI}
          className="lg:h-[30px] h-6 rounded-full lg:w-[30px] w-6"
        />
      ) : (
        <h1 className="font-akira lg:text-xs text-[10px]">Chain</h1>
      )}
      <IoMdArrowDropdown
        onClick={handleClick}
        className="text-2xl cursor-pointer"
      />
    </div>
  );
};
export default NavAssetCard;
