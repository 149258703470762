import React, { useEffect, useState } from "react";
import galxLogoURI from "../assets/galx.png";
import { useQuery } from "@tanstack/react-query";
import { getCalculator } from "../api/misc";
import { useCalculator } from "../hooks/useCalculator";
const CalculatorCard = ({
  name,
  otherPoints,
  blazPoints,
  logoURI,
  calculatedPoints,
}) => {
  return (
    <div className='border cursor-pointer rounded-[20px] sm:text-[10px] text-[8px] font-redHat border-[#666666] transition-all duration-200 ease-in-out hover:border-primary hover:shadow-[0_0_30px_rgba(255,53,03,0.4)] md:py-5 py-3 md:px-8 sm:px-5 px-3'>
      <div className='flex items-center gap-x-1'>
        <img src={logoURI} className='2xl:w-4 2xl:h-4 w-3 h-3' alt={logoURI} />
        <h1 className='2xl:text-base text-xs uppercase font-bold '>{name}</h1>
      </div>
      <div className='flex'>
        <div className='flex  flex-col w-1/2 items-center lg:px-4  border-r border-[#666666]'>
          <h2 className='mt-2 font-bold'>
            1 {name} points = {calculatedPoints} Blaz Points
          </h2>
          <img
            src={logoURI}
            className='md:h-[60px] sm:h-12 h-9 md:w-[60px] sm:w-12 w-9 sm:mt-3 mt-2'
            alt=''
          />
        </div>
        <div className='flex  w-1/2 flex-col items-start'>
          <div className='flex w-full 2xl:pb-3 sm:pl-3 pl-1 border-b border-[#666666] flex-col items-start'>
            <h2 className='w-full'>{name} points</h2>
            <h1 className='text-[#ff3503] font-bold md:text-2xl sm:text-xl text-[14px]'>
              {name === "taskon" ? "Coming Soon" : otherPoints}
            </h1>
          </div>
          <div className='flex sm:pl-3 pl-1 2xl:mt-3 mt-1 flex-col items-start'>
            <h2 className='w-full'>Blaz points</h2>
            <h1 className='text-[#ff3503] font-bold md:text-2xl sm:text-xl text-[14px]'>
              {name === "taskon" ? "Coming Soon" : blazPoints}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
const calculateFormulae = [
  {
    name: "zealy",
    points: 50,
  },
  {
    name: "galxe",
    points: 7350,
  },
  {
    name: "intract",
    points: 0,
  },
  {
    name: "taskon",
    points: "Coming Soon",
  },
  {
    name: "totalPoints",
    points: 0,
  },
];
const Calculator = () => {
  const [calculateData, setCalculateData] = useState([]);
  const { data, isLoading, isError } = useCalculator();
  useEffect(() => {
    if (data && !isLoading) {
      const resultantData = Object.entries(data).map(([key, value]) => ({
        name: key,
        ...value,
      }));
      const requiredData = resultantData
        ?.map((item) => {
          const found = calculateFormulae?.find(
            (calculate) => calculate?.name === item?.name
          );
          return found ? { ...item, ...found } : item;
        })
        .filter((item) => item.name !== "totalsPoints");
      setCalculateData(requiredData);
    }
  }, [isLoading, data, isError]);

  return (
    <div className='min-h-screen  2xl:pt-28 sm:pt-24 pt-20 w-full'>
      <div className='xl:container xl:pb-0 pb-8 w-full xl:px-0 sm:px-12 px-5 mx-auto flex flex-col items-start'>
        <h1 className='md:text-6xl md:text-left text-center w-full  text-3xl font-bold font-akira'>
          Calculator
        </h1>
        <p className='mt-2 w-full md:text-left text-center text-2xl font-redHat font-normal'>
          Calculate your Blazpay Point
        </p>
        <div className='mt-8 2xl:w-[1200px] xl:w-[1000px] lg:w-[900px] w-full sm:rounded-[40px] rounded-[32px] grid lg:grid-cols-3 grid-cols-2 2xl:gap-8 sm:gap-5 gap-3 border-2 bg-black lg:p-10 md:p-7 sm:p-5 p-3'>
          {calculateData?.map((item, index) => {
            return (
              <CalculatorCard
                name={item?.name}
                logoURI={galxLogoURI}
                otherPoints={item?.xp}
                calculatedPoints={item?.points}
                blazPoints={item?.blazpayXp}
              />
            );
          })}
        </div>
        <div className='flex 2xl:gap-x-16 lg:gap-x-12 lg:w-fit w-full lg:justify-start justify-between items-center 2xl:mt-8 mt-12'>
          <h2 className='2xl:text-4xl lg:text-3xl md:text-xl sm:text-base text-xs font-akira'>
            Accumulative Blazpoints
          </h2>
          <div
            style={{
              boxShadow: "0 8px 24px -16px #fff inset,0 0 12px 0 #a9a9a9 inset",
            }}
            className='font-akira 2xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-[13px] transition-all duration-[400ms] ease hover:scale-[.95] bg-black  md:py-2 py-1 md:px-16 sm:px-12 px-6 rounded-full border-[3px] border-primary'
          >
            {calculateData.find((item) => item.name === "totalsPoints")
              ?.blazpayXp || 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
