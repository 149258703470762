import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../api/misc";

export const useLeaderboard = (
  currentPage = 1,
  limit = 10,
  from,
  to,
  dropdownData
) => {
  const leaderboardQuery = useQuery({
    queryKey: ["leaderboard", currentPage, dropdownData, from, to],
    queryFn: () =>
      getLeaderboard({
        page: currentPage,
        limit: limit,
        chainId: dropdownData.chainId,
        from,
        to,
      }),
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return leaderboardQuery;
};
