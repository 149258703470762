import React from "react";
import { HiArrowUpRight } from "react-icons/hi2";

const BottomCard = ({ title }) => {
  return (
    <div
      style={{
        backdropFilter: "blur(8px)",
        background:
          "linear-gradient(to right,rgba(175, 162, 159, 0.31),rgba(66, 18, 8, 0.31),rgba(83, 40, 31, 0.31),rgba(220, 220, 220, 0.31)",
      }}
      className="absolute w-[calc(94%)] md:rounded-3xl rounded-2xl py-2 md:h-24 h-20 md:px-8 sm:px-5 px-3 left-1/2 -translate-x-1/2  lg:hidden flex justify-between items-center  bottom-4"
    >
      <h1 className="md:text-xl sm:text-base text-[13px] font-akira w-full font-bold">
        {title}
      </h1>
      <div
        className={`sm:h-8 sm:w-9 h-7 w-7 rounded-full flex items-center justify-center bg-white`}
      >
        <HiArrowUpRight className="sm:text-base text-[13px] font-bold text-black" />
      </div>
    </div>
  );
};

export default BottomCard;
