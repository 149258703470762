import { useLocation, Navigate } from "react-router";
import PropTypes from "prop-types";
import { useAuth } from "../hooks/useAuth";

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/" state={{ redirectPath: location.pathname }} />
  );
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
