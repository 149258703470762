import React from "react";
import qaCardBg from "../../assets/qa_bg.svg";
import BottomCard from "./BottomCard";
import Tilt from "react-parallax-tilt";
const QuizCard = ({ title, className, logoURI }) => {
  return (
    <Tilt
      className={`${className} relative  group flex flex-col overflow-hidden items-center lg:justify-start
     justify-center lg:shadow-[0_0_30px_rgba(216,182,110,0.9)] shadow-[0_0_40px_rgba(255,81,0,0.7)]
      w-full xl:px-7 lg:px-5 px-3 xl:py-6 lg:py-4 lg:py-8 py-6 lg:rounded-[22px] rounded-3xl
       md:border-[0.5px] border-[1px] `}
      tiltMaxAngleX={15}
      tiltMaxAngleY={15}
      perspective={800}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div>
        <h1 className="lg:text-base lg:block hidden text-center font-akira uppercase font-bold">
          {title}
        </h1>
        <img
          src={logoURI}
          className="h-fit z-10 w-fit lg:mt-0  2xl:scale-11 xl:scale-100 lg:scale-90 scale-[1.2] 2xl:mt-4 group-hover:scale-90 transtion-all duration-[250ms] ease"
          alt={logoURI}
        />
        <img
          src={qaCardBg}
          alt=""
          className="z-[-99] absolute h-full  w-full top-0 lg:scale-y-[1.3] md:scale-[1.5] sm:scale-[1.7] scale-[3] left-0"
        />
        <BottomCard title={title} />
      </div>
    </Tilt>
  );
};

export default QuizCard;
