import React from "react";
import { useNavigate } from "react-router-dom";

const EntrypassCard = ({ logoURI, type, name, balance, link }) => {
  const handleClick = (link) => {
    console.log("link click", link);
    window.open(`https://blazpay.com/entry-pass/${link}`);
  };
  return (
    <div className="rounded-[18px] cursor-pointer hover:scale-[.95] group transition-all duration-[400ms]  2xl:h-[200px] sm:h-[160px] h-[260px] 2xl:w-[220px]  xl:w-[200px] sm:w-[190px] w-[280px] py-4 px-2 flex items-start border border-[#666666] ">
      <div className="flex  flex-col items-center">
        <div className="2xl:h-[130px] 2xl:w-[100px] sm:h-[100px] h-[180px] sm:w-[80px] w-[160px]">
          <img src={logoURI} alt={logoURI} className="h-fit w-fit" />
        </div>
        <div className="flex gap-x-1 items-center  mt-1">
          <span className="text-[9px] font-bold text-[#666666]">{type}</span>
        </div>
      </div>
      <div className="flex w-full px-2 font-bold sm:text-[11px] text-[15px] flex-col 2xl:pt-6 xl:pt-3 sm:pt-0 pt-10 items-center">
        <h1>Blazpay</h1>
        <h2>X</h2>
        <h1 className="text-center text-[#999999]">{name}</h1>
        <span className="font-light text-[8px] mt-1">
          Owned Passes : {balance}
        </span>
        <button
          onClick={() => handleClick(link)}
          style={{
            boxShadow:
              "0 3px 6px -4px #fff inset,0 -6px 7px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
          }}
          className="px-4 py-1 2xl:mt-2 xl:mt-1 mt-2 text-[8px] transition-all duration-[150ms] ease-in hover:scale-[.90] font-bold rounded-full bg-radial-play border-none outline-none"
        >
          Get More
        </button>
      </div>
    </div>
  );
};

export default EntrypassCard;
