import React from "react";
import { IoIosAdd } from "react-icons/io";
const colors = ["#6dd0e7", "#ff7c03", "#ff3503", "#ff5500", "#ff5510"];
const NftCard = ({
  logoURI,
  className,
  title,
  handleClick,
  price,
  index,
  handleBuyNow,
}) => {
  return (
    <div
      style={{
        background: "linear-gradient(0deg,#D6D6D6,#000000,#FFFFFF)",
      }}
      className={`rounded-[20px] hover:shadow-[0_4px_10px_rgba(255,124,3,0.6)] overflow-hidden relative group 2xl:h-[320px] h-[260px]  w-full ${className} `}
    >
      <div className="bg-black absolute h-[calc(100%-2px)] w-[calc(100%-2px)] mt-[1px] ml-[1px] rounded-[20px] flex flex-col items-center p-2">
        <div
          style={{ backgroundColor: colors[index % colors.length] }}
          className={`h-full w-full rounded-[18px] flex justify-center items-center
                transition-all duration-300   group-hover:shadow-[0_4px_20px_rgba(255,124,3,0.8)]`}
        >
          <img src={logoURI} alt="" className="h-[80%] w-[80%] rounded-2xl" />
        </div>
        <div className="flex flex-col gap-y-1 text-center pt-2 sm:pb-5 pb-2 px-2 items-center">
          <h1 className="2xl:text-xs text-[8px] font-bold font-akira">
            {title}
          </h1>
          <div className="flex gap-x-3 items-center">
            <h1 className="2xl:text-xs text-[10px] font-medium">Blazprice</h1>
            <span className="text-[10px] font-bold text-[#ff3503]">
              {price}
            </span>
            <div className="2xl:h-7 h-5 2xl:w-7 w-5 cursor-pointer rounded-[4px] border justify-center flex items-center">
              <IoIosAdd
                className="2xl:text-xl text-base"
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={handleBuyNow}
        style={{ backgroundColor: colors[index % colors.length] }}
        className={`text-center w-full text-white cursor-pointer rounded-br-[16px] rounded-bl-[16px] absolute  font-bold py-[2px] -bottom-7
         group-hover:bottom-0 transition-all duration-[300ms] ease text-[10px]`}
      >
        Buy Now
      </div>
    </div>
  );
};

export default NftCard;
