import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
const CarouselArrows = () => {
  return (
    <>
      <div className="flex justify-between items-center w-full relative 2xl:-top-[380px] sm:px-0 px-8 xl:-top-[340px] -top-72 !z-50">
        <div className="custom-prev hover:opacity-90 group hover:scale-[.96] active:scale-[.90] transition-all duration-[120ms] ease-in-out  flex items-center justify-center xl:-ml-0 lg:-ml-5  h-10 w-10 rounded-full bg-[#d9d9d9]">
          <IoIosArrowRoundBack className="md:text-3xl text-2xl text-black transition-all duration-[250ms] ease-in-out group-active:-left-1 relative" />
        </div>
        <div className="custom-next hover:opacity-90 group hover:scale-[.96] active:scale-[.90] transition-all duration-[120ms] ease-in-out flex items-center justify-center xl:-mr-0 lg:-mr-5 h-10 w-10 rounded-full bg-[#d9d9d9]">
          <IoIosArrowRoundForward className="md:text-3xl text-2xl text-black transition-all duration-[250ms] ease-in-out group-active:left-1 relative" />
        </div>
      </div>
    </>
  );
};

export default CarouselArrows;
