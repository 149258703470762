import { useState, useEffect } from "react";
import DotLoader from "../components/loaders/DotLoader";
import ClientPaginationCard from "../components/common/ClientPaginationCard";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import LeaderboardNavs from "../components/leaderboard/LeaderboardNavs";
import WinnerWrapper from "../components/leaderboard/WinnerWrapper";
import UserCard from "../components/leaderboard/UserCard";
import PaginationCard from "../components/common/PaginationCard";
import ChainSelectDropdown from "../components/leaderboard/ChainSelectDropdown";
import { useLeaderboard } from "../hooks/useLeaderboard";
import { useReferralList } from "../hooks/useReferralList";

const Leaderboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentReferralPage, setCurrentReferralPage] = useState(1);
  const [count, setCount] = useState(0);
  const [timeframe, setTimeframe] = useState("");
  const [currentNav, setCurrentNav] = useState("all");
  const [referrals, setReferrals] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [referralInput, setReferralInput] = useState("");
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [from, setFrom] = useState(null);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [to, setTo] = useState(null);
  const [top3, setTop3] = useState({});
  const [dropdownData, setDropdownData] = useState({
    imgPath: "",
    chainName: "All",
    chainId: "",
  });
  const itemsPerPage = 10;
  const handleTimeframe = (selectedTimeframe) => {
    setCount(0);
    setLeaderboardList([]);
    if (timeframe === selectedTimeframe) {
      setTimeframe("");
      setFrom(null);
      setTo(null);
    } else {
      setTimeframe(selectedTimeframe);
      const today = new Date();
      let newFrom, newTo;

      if (selectedTimeframe === "daily") {
        newFrom = startOfDay(today).toISOString();
        newTo = endOfDay(today).toISOString();
      } else if (selectedTimeframe === "weekly") {
        newFrom = startOfWeek(today).toISOString();
        newTo = endOfWeek(today).toISOString();
      } else if (selectedTimeframe === "monthly") {
        newFrom = startOfMonth(today).toISOString();
        newTo = endOfMonth(today).toISOString();
      }

      setFrom(newFrom);
      setTo(newTo);
    }
    setCurrentPage(1);
  };

  const chains = [
    {
      id: 30732,
      name: "Movement EVM Testnet",
      tokenImg: "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
      mask: "Testnet",
    },
    {
      id: 167008,
      name: "Taiko Katla L2",
      tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
      mask: "Testnet",
    },
    {
      id: 169,
      name: "Manta Pacific",
      tokenImg: "https://i.ibb.co/CPzhyTf/manta.png",
      mask: "Mainnet",
    },
    {
      id: 56,
      name: "BNB Smart Chain Mainnet",

      tokenImg: "https://i.ibb.co/VMVS2Hc/binance.png",
      mask: "Mainnet",
    },
    {
      id: 137,
      name: "Polygon Mainnet",

      tokenImg: "https://i.ibb.co/MDKTCF6/polygon.png",
      mask: "Mainnet",
    },
    {
      id: 1891,
      name: "Pegasus Testnet",
      tokenImg:
        "	https://airdrops.io/wp-content/uploads/2023/07/Lightlink-logo.jpg",
      mask: "Testnet",
    },
    {
      id: 17000,
      name: "Holesky",

      tokenImg: "https://i.ibb.co/tHbx9Jw/arbitrum.png",
      mask: "Mainnet",
    },
  ];

  const allChainData = {
    name: "All",
    tokenImg: [
      "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
      "https://i.ibb.co/CPzhyTf/manta.png",
      "https://airdrops.io/wp-content/uploads/2023/07/Lightlink-logo.jpg",
      "https://i.ibb.co/y5D8dJv/taiko.png",
    ],
    mask: "",
  };
  const chainData = [allChainData, ...chains];
  const leaderboardQuery = useLeaderboard(
    currentPage,
    10,
    from,
    to,
    dropdownData
  );
  const { referralData, referralIsLoading, referralIsError } =
    useReferralList(currentPage);

  useEffect(() => {
    if (referralData?.leaderboard?.length > 0) {
      setReferrals(referralData?.leaderboard);
      setTotalReferrals(referralData?.leaderboard?.length / itemsPerPage);
    }
  }, [referralData, referralIsLoading, referralIsError]);

  const handleNextPage = () => {
    if (currentPage < leaderboardQuery.data?.totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setCount((x) => x + 10);
      setLeaderboardList(null);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    setCount((x) => x - 10);
    setLeaderboardList(null);
  };
  const handleNextReferralPage = () => setCurrentReferralPage((x) => x + 1);
  const handlePrevReferralPage = () => setCurrentReferralPage((x) => x - 1);
  // const handleChainData = (index) => {
  //   const selectedChain = chainData[index];
  //   setLeaderboardList(null);
  //   if (selectedChain.name === "All") {
  //     setDropdownData({
  //       imgPath: selectedChain.tokenImg,
  //       chainName: selectedChain.name,
  //       chainId: "",
  //     });
  //   } else {
  //     setDropdownData({
  //       imgPath: selectedChain.tokenImg,
  //       chainName: selectedChain.name,
  //       chainId: selectedChain.id,
  //     });
  //   }
  // };
  const handleChainData = (selectedChain) => {
    setLeaderboardList(null);
    if (selectedChain?.name === "All") {
      setDropdownData({
        imgPath: selectedChain.tokenImg,
        chainName: selectedChain.name,
        chainId: "",
      });
    } else {
      setDropdownData({
        imgPath: selectedChain.tokenImg,
        chainName: selectedChain.name,
        chainId: selectedChain.id,
      });
    }
  };
  const filteredList = leaderboardList?.filter((item) =>
    item?.username?.toLowerCase().includes(searchInput.toLowerCase())
  );
  const filteredReferrals = referrals
    ?.slice(
      (currentReferralPage - 1) * itemsPerPage,
      currentReferralPage * itemsPerPage
    )
    ?.filter((referral) =>
      referral?.username?.toLowerCase()?.includes(referralInput?.toLowerCase())
    );
  useEffect(() => {
    if (
      leaderboardQuery?.data &&
      leaderboardQuery?.data?.leaderboard?.length > 0
    ) {
      setTop3(leaderboardQuery?.data?.leaderboard?.slice(0, 3));
    }
  }, [leaderboardQuery?.data, leaderboardQuery?.isLoading]);
  useEffect(() => {
    if (
      leaderboardQuery?.data?.leaderboard?.length > 0 &&
      !leaderboardQuery?.isLoading
    ) {
      setLeaderboardList(leaderboardQuery?.data?.leaderboard);
    }
  }, [handleNextPage, handlePrevPage]);

  return (
    <div className="min-h-screen  text-white md:pt-28 pt-24 w-full">
      <div className="flex md:flex-row flex-col md:items-start xl:px-5 px-8  xl:container w-full mx-auto justify-between items-center">
        <div className="flex md:w-1/2 w-full md:text-left text-center flex-col justify-between items-start">
          <h1 className="lg:text-6xl  w-full sm:text-4xl text-3xl font-bold font-akira">
            Leaderboard
          </h1>
          <p className="font-redHat w-full md:px-0 px-8 leading-[1.2] mt-3 xl:text-2xl md:text-xl sm:text-base text-xs">
            Show off your stats and collectibles with your unique profile. Team
            features will be revealed soon!
          </p>
        </div>
        <WinnerWrapper top3={top3} isLoading={leaderboardQuery?.isLoading} />
      </div>
      <div className="flex flex-col lg:px-0 px-6 xl:pb-10 pb-20 relative container items-center  mx-auto 2xl:mt-12 xl:mt-4 md:mt-20 mt-24">
        <LeaderboardNavs
          currentNav={currentNav}
          setCurrentNav={setCurrentNav}
          handleClick={handleTimeframe}
          value={searchInput}
          setInputValue={setSearchInput}
          chain={dropdownData}
          handleClickDropDown={setIsDropdownActive}
          isDropdownActive={isDropdownActive}
          referralInput={referralInput}
          setReferralInput={setReferralInput}
        />
        <div className="mt-6  w-full 2xl:max-w-[1000px] xl:max-w-[800px] max-w-[760px]  flex flex-col gap-y-3">
          <DotLoader
            isLoading={leaderboardQuery?.isLoading || referralIsLoading}
            className={"h-60"}
          />
          {currentNav === "referral" ? (
            <>
              {filteredReferrals?.map((data, index) => {
                return (
                  <UserCard
                    key={data?.userId}
                    name={data?.username}
                    points={data?.totalReferred}
                    rank={index + count + 1}
                    module="referrals"
                  />
                );
              })}
            </>
          ) : (
            <>
              {leaderboardQuery.data?.userRank && (
                <UserCard
                  name={"You"}
                  rank={leaderboardQuery?.data?.userRank}
                  points={leaderboardQuery?.data?.userPoints}
                  active={true}
                  module="leaderboard"
                />
              )}
              {filteredList?.map((data, index) => {
                return (
                  <UserCard
                    key={index}
                    name={data?.username}
                    points={data?.totalPoints}
                    active={false}
                    rank={index + count + 1}
                  />
                );
              })}
            </>
          )}
        </div>
        <PaginationCard
          handleNextClick={handleNextPage}
          handlePrevClick={handlePrevPage}
          className={"absolute xl:right-32 right-1/2 translate-x-1/2  bottom-8"}
          currentPage={currentPage}
          isActive={!leaderboardQuery?.isLoading && currentNav !== "referral"}
        />
        <ClientPaginationCard
          isActive={currentNav === "referral"}
          totalPages={totalReferrals}
          currentPage={currentReferralPage}
          handleNextClick={handleNextReferralPage}
          handlePrevClick={handlePrevReferralPage}
          className={"absolute xl:right-32 right-1/2 translate-x-1/2  bottom-8"}
        />
        <ChainSelectDropdown
          isActive={isDropdownActive}
          setIsActive={setIsDropdownActive}
          className="absolute md:right-0 right-1/2 xl:top-0 md:top-12  md:translate-x-0 translate-x-1/2 z-20"
          data={chainData}
          handleChainClick={handleChainData}
        />
      </div>
    </div>
  );
};

export default Leaderboard;
