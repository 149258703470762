import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { useDisconnect } from "@web3modal/ethers5/react";
import bgImage from "../assets/dashboard_bg.png";
import { useWallet } from "../hooks/useWallet";
import { NFTRequiredError } from "../services/error";
import { getBalance } from "../api/user";
import { apiCall } from "../services/ApiCall";
import configs from "../config/configs.json";

export const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    isLoading: true,
    isAuthenticated: false,
    user: null,
    tokens: 0,
  });

  const wallet = useWallet();

  const { disconnect } = useDisconnect();

  const login = useCallback(
    async ({ address, chainId }) => {
      try {
        const signer = wallet.provider.getSigner();
        const { message } = await axios.get("/api/auth/check-address", {
          params: { address, chainId },
        });

        const signature = await signer.signMessage(message);
        const response = await axios.post("/api/auth/login", {
          message,
          signature,
          chainId,
        });

        axios.setToken(response.token);
        localStorage.setItem("token", response.token);

        setState((prev) => ({
          ...prev,
          isLoading: false,
          isAuthenticated: true,
          token: response.token,
          user: response.user,
        }));
      } catch (err) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));

        if (err.response?.status === 400) {
          throw new NFTRequiredError("Nft is required");
        }

        throw err;
      }
    },
    [wallet.provider]
  );

  const logout = useCallback(() => {
    axios.setToken(null);
    localStorage.removeItem("token");

    disconnect().catch(console.error);

    setState((prev) => ({
      ...prev,
      isAuthenticated: false,
      user: null,
      balanceData: null,
    }));
  }, [disconnect]);

  useMemo(() => {
    if (state.isAuthenticated) {
      logout();
    }
  }, [wallet.address]);

  const updateProfile = useCallback(async (data) => {
    setState((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        ...data,
      },
    }));
  }, []);

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        return data.data?.rewardCount;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateTokens = useCallback(async () => {
    if (!wallet.address) return;

    const balance = await getBalance(wallet.address);

    console.log(balance);

    console.log("balance :", balance);

    if (balance.status) {
      setState((prev) => ({
        ...prev,
        tokens: balance.data,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        tokens: balance,
      }));
    }
  }, [wallet]);

  useEffect(() => {
    if (state.isAuthenticated) {
      updateTokens();
    }
  }, [wallet.address, state.isAuthenticated]);

  useEffect(() => {
    const init = async () => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const accessToken = localStorage.getItem("token");

      if (!accessToken) {
        return setState((prev) => ({
          ...prev,
          isLoading: false,
          isAuthenticated: false,
        }));
      }

      axios.setToken(accessToken);

      const profileData = await axios.get(`/api/user/profile`);

      setState((prev) => ({
        ...prev,
        isLoading: false,
        isAuthenticated: true,
        token: accessToken,
        user: profileData,
      }));
    };

    init().catch((err) => {
      console.error(err);

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      if (err.response?.status === 401) {
        axios.setToken(null);
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      isLoading: state.isLoading,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      tokens: state.tokens,
      login,
      logout,
      updateProfile,
      updateTokens,
    }),
    [
      state.isLoading,
      state.isAuthenticated,
      state.user,
      state.tokens,
      login,
      logout,
      updateProfile,
      updateTokens,
    ]
  );

  if (state.isLoading) {
    return (
      <div
        className="fixed top-0 left-0 z-50 w-screen h-screen flex  justify-center  items-center "
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <div className="loader-full" />
      </div>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
