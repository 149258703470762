import React from "react";

const MainEntrypassCard = ({ logoURI }) => {
  return (
    <div className="flex flex-col  xl:pt-0 sm:pt-8  items-center">
      <div className="2xl:h-[460px] xl:h-[420px] md:h-[360px] sm:h-[320px] h-fit p-5 2xl:w-[370px] xl:w-[340px] md:w-[300px] sm:w-[260px] w-[300px]">
        <img src={logoURI} alt="" className="h-full w-full" />
      </div>
      <span className="text-base font-redHat font-bold xl:px-20 px-12 py-3 rounded-full border border-[#666666]">
        You Own 01
      </span>
    </div>
  );
};

export default MainEntrypassCard;
