import React from "react";
import FilledButton from "./FilledButton";

const GameCard = ({ logoURI, name, handleClick, active, className }) => {
  return (
    <div
      style={{
        background: "linear-gradient(0deg,#D6D6D6,#000000,#FFFFFF)",
      }}
      className={`${className} 2xl:rounded-[40px] rounded-[36px] relative cursor-pointer group 2xl:h-[600px] xl:h-[480px] lg:h-[430px] h-[400px] 2xl:w-[400px] xl:w-[320px] lg:w-[280px] w-[260px]`}
    >
      <div className="bg-black absolute h-[calc(100%-4px)] w-[calc(100%-4px)] mt-[2px] ml-[2px] rounded-[40px] flex flex-col items-center p-4">
        <div
          className={`h-full w-full  rounded-3xl flex justify-center items-center
                bg-gradient-to-b from-[#141419] to-[#3d3d4d]
                transition-all duration-300 group-hover:from-transparent group-hover:to-transparent group-hover:shadow-[0_4px_20px_rgba(255,124,3,0.8)] group-hover:bg-[#ff7c03]`}
        >
          <img
            src={logoURI}
            className="h-fit w-fit 2xl:scale-[1.3] 2xl:group-hover:scale-[1.4] group-hover:scale-110 transition-all duration-[400ms] ease-in-out"
            alt=""
          />
        </div>
        <div className="flex 2xl:mt-6 2xl:mb-4 mb-2 mt-3 flex-col items-center gap-y-4">
          <h1 className="2xl:text-2xl xl:text-xl text-base text-center font-bold font-akira">
            {name}
          </h1>
          <div className="w-fit">
            <FilledButton text={"Play"} handleClick={handleClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
