import React from "react";
import { IoCartOutline } from "react-icons/io5";
const CartButton = ({ handleClick, itemCount }) => {
  return (
    <div
      onClick={handleClick}
      typeof="button"
      className="flex gap-x-2 relative items-center border border-[#999999] bg-[#141419] rounded-[18px] cursor-pointer sm:px-5 px-3 sm:py-2 py-[6px]"
    >
      <span className="text-[14px]">Your Cart</span>
      <IoCartOutline className="sm:text-xl text-base" />
      {itemCount > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex justify-center items-center text-sm">
          {itemCount}
        </span>
      )}
    </div>
  );
};

export default CartButton;
