import { Outlet, createBrowserRouter } from "react-router-dom";
import GuestGuard from "./guards/GuestGuard";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import AuthGuard from "./guards/AuthGuard";
import Questions from "./pages/Questions";
import Spin from "./pages/games/Spin";
import SlotMachine from "./pages/games/SlotMachine";
import UserProfile from "./pages/profile";
import Store from "./pages/Store";
import MyItems from "./pages/profile/MyItems";
import MyTransactions from "./pages/profile/MyTransactions";
import Entrypasses from "./pages/profile/MyEntryPass";
import Leaderboard from "./pages/Leaderboard";
import Cart from "./pages/Cart";
import Memory from "./pages/games/Memory";
import PuzzleSlider from "./pages/games/PuzzleSlider";
import WordSearch from "./pages/games/WordSearch";
import GamingMarketplace from "./pages/GamingMarketplace";
import Referral from "./pages/Referral";
import ReferralHistory from "./pages/ReferralHistory";
import DailyTasks from "./pages/DailyTasks";
import Calculator from "./pages/Calculator";

const router = createBrowserRouter([
  {
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
        index: true,
      },
    ],
  },
  {
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      { path: "/dashboard", element: <Dashboard />, index: true },
      { path: "/questions", element: <Questions /> },
      { path: "/store", element: <Store /> },
      { path: "/leaderboard", element: <Leaderboard /> },
      { path: "/gaming-marketplace", element: <GamingMarketplace /> },
      { path: "/games/spin", element: <Spin /> },
      { path: "/games/slot-machine", element: <SlotMachine /> },
      { path: "/games/memory", element: <Memory /> },
      { path: "/games/sliding-puzzle", element: <PuzzleSlider /> },
      { path: "/games/word-search", element: <WordSearch /> },
      { path: "/user/profile", element: <UserProfile /> },
      { path: "/user/items", element: <MyItems /> },
      { path: "/user/transactions", element: <MyTransactions /> },
      { path: "/user/pass", element: <Entrypasses /> },
      { path: "/refer", element: <Referral /> },
      { path: "/refer/referral-history", element: <ReferralHistory /> },
      { path: "/daily-tasks", element: <DailyTasks /> },
      { path: "/calculator", element: <Calculator /> },
    ],
  },
]);

export default router;
