import React from "react";

const InputField = ({
  className,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  type = "text",
  id,
  module = "create",
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`${className} w-full bg-transparent outline-none focus:border-[#9a9a9a] focus:border-2
       transition-all duration-250 
       ${
         module === "create"
           ? "md:px-12 sm:px-8 px-4 2xl:py-5 xl:py-4 sm:rounded-2xl rounded-xl lg:py-3 py-[10px] 2xl:text-2xl lg:text-xl sm:text-base text-[13px]"
           : "xl:text-xl md:text-base sm:text-[14px] text-xs 2xl:py-4 xl:py-3 py-2 md:px-7 sm:px-5 px-4 2xl:rounded-3xl rounded-[20px]"
       } border border-[#666666] `}
      required={required}
      disabled={disabled}
      id={id}
    />
  );
};

export default InputField;
