import { useQuery } from "@tanstack/react-query";
import { getQuestions } from "../api/misc";
import { useWallet } from "./useWallet";

export const useQuestions = () => {
  const wallet = useWallet();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["questions", wallet?.chainId],
    queryFn: () => getQuestions(wallet.chainId),
    enabled: !!wallet.chainId,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  return { data, isLoading, isError };
};
