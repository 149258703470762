import React from "react";
const CustomRewardCard = ({ text, value, className }) => {
  return (
    <div
      style={{
        boxShadow:
          "0 3px 12px -3px rgba(255,255,255,1) inset, 0 0 6px 0 #a9a9a9",
      }}
      className={`${className} flex flex-col items-center w-fit  font-redHat rounded-3xl bg-black xl:px-20 sm:px-16 px-12 md:py-5 py-3`}
    >
      <h2 className="2xl:text-xl text-[13px] font-weight font-redHat">
        {text}
      </h2>
      <h1 className="2xl:text-5xl md:text-[36px] text-3xl  leading-[1.1] font-bold text-[#ff3503]">
        {value}
      </h1>
    </div>
  );
};

export default CustomRewardCard;
