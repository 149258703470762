import React from "react";

const StakeButton = ({ handleClick, isLoading, text, disabled }) => {
  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      className={`md:text-[18px] sm:text-xs text-[10px] transition-all duration-200
         ease ${
           disabled ? "opacity-75" : "hover:scale-95"
         } shadow-xl bg-white text-[#ff5500] font-bold font-redHat
          rounded-full md:py-[14px] sm:py-[10px] py-[7px] xl:px-20 lg:px-16 md:px-20 sm:px-12 px-8`}
    >
      {text}
    </button>
  );
};
export default StakeButton;
