import React from "react";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
const ReferralCard = ({ code, handleCopy, className, isCopied }) => {
  return (
    <div
      className={`${className} flex w-64  justify-between items-center rounded-[18px] border border-[#666666] bg-[#141419] px-6 py-[14px]`}
    >
      <span
        onClick={handleCopy}
        className="md:text-base text-[13px] cursor-pointer font-redHat"
      >
        {code}
      </span>
      {isCopied ? (
        <LuCopyCheck className="text-xl text-blue-600" />
      ) : (
        <LuCopy
          onClick={handleCopy}
          className="text-xl text-[#8c8c8c] cursor-pointer"
        />
      )}
    </div>
  );
};

export default ReferralCard;
