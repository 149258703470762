import React from "react";
import LinkButton from "./LinkButton";

const QuestionCard = ({ handleClick, className, text, title, logoURI }) => {
  return (
    <div
      onClick={handleClick}
      style={{
        boxShadow: "0 0 30px rgba(254,124,4,1)",
      }}
      className="bg-[#0b0b0b] relative lg:order-3 order-1 cursor-pointer group lg:w-[30%] md:w-[calc(50%-24px)] sm:w-[calc(50%-18px)] w-[calc(50%-16px)] border border-[#666666] md:px-8 sm:px-6 px-5 md:py-7 py-4 sm:py-3 py-[10px] relative rounded-3xl lg:h-[100%] md:h-[50%] sm:h-[220px] h-[150px]"
    >
      <LinkButton
        className={"absolute 2xl:right-10 2xl:top-10 right-5 top-4"}
      />
      <div className="flex flex-col items-start">
        <h1 className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl  text-base font-akira font-bold">
          {title}
        </h1>
        <p className="sm:text-xs text-[10px] uppercase font-redHat">{text}</p>
      </div>
      <img
        style={{
          filter: "drop-shadow(-5px -5px 30px rgba(255, 216, 142, 0.7))",
        }}
        src={logoURI}
        alt=""
        className="absolute 2xl:right-10 2xl:bottom-8 md:right-5 right-0   md:bottom-5 -bottom-6  h-fit w-fit md:scale-[.85] scale-[.5] group-hover:scale-[.75] transition-all duration-[400ms] ease-in-out"
      />
    </div>
  );
};

export default QuestionCard;
