import React from "react";
import { IoIosArrowBack } from "react-icons/io";
const CardLayout = ({
  title,
  className,
  handleBack,
  children,
  border = true,
}) => {
  return (
    <div className="min-h-screen sm:px-8 px-5 2xl:pt-20 sm:pt-16 pt-10 w-full">
      <div className="lg:container w-full pt-10 pb-5 2xl:px-24 xl:px-8 mx-auto">
        <div
          className={`h-full  bg-[#111111] ${border && "border"} ${className}`}
        >
          <div className="flex xl:gap-x-20 lg:gap-x-16 sm:gap-x-5 gap-x-3 items-center w-full">
            <IoIosArrowBack
              onClick={handleBack}
              className="xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl hover:opacity-70 cursor-pointer font-bold"
            />
            <h1 className="uppercase 2xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-[14px] font-akira font-bold bg-gradient-to-b from-[#fff] to-[#666666] bg-clip-text text-transparent">
              {title}
            </h1>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CardLayout;
