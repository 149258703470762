import React, { useEffect, useState } from "react";
import LinkButton from "./LinkButton";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "../../hooks/useWallet";
import { addToCart, getCart, getProducts } from "../../api/product";

const NftWrapper = ({ img, index, title }) => {
  return (
    <div
      className={`${
        index === 1
          ? "2xl:w-[120px] lg:w-[90px] sm:w-[140px] w-[110px] 2xl:h-[160px] lg:h-[116px]  sm:h-[190px] h-[160px]"
          : "2xl:h-[145px] lg:h-[100px] sm:h-[180px] h-[140px] 2xl:w-[105px] lg:w-[76px] sm:w-[130px] w-[90px]"
      } rounded-lg  bg-gradient-to-b from-[#FF3503] to-[#F3B53D] relative`}
    >
      <div className="absolute  flex-col flex p-1 items-center h-[calc(100%-3px)] w-[calc(100%-3px)] ml-[1.5px] mt-[1.5px] rounded-lg bg-gradient-to-b from-[#FF5500] to-[#070707]">
        <img src={img} alt="" className="rounded-[6px]" />
        <p className="2xl:text-[8px] lg:text-[6px] md:text-xs sm:text-[10px] text-[10px] 2xl:mt-3 font-redHat text-center mt-1 xl:leading-[1.2] leading-[1.0] font-bold text-center">
          {title}
        </p>
      </div>
    </div>
  );
};

const NftMarketCard = ({ handleClick }) => {
  const wallet = useWallet();
  const [cartItemCount, setcartItemCount] = useState(0);

  const products = useQuery({
    queryKey: ["products", wallet.chainId],
    queryFn: () => getProducts({ chainId: wallet.chainId }),
    enabled: !!wallet.chainId,
  });

  const fetchCart = async (chainId) => {
    try {
      let cartData = await getCart({ chainId });
      (cartData = cartData.items.filter((item) => item.quantity >= 1)),
        setcartItemCount(cartData.length);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, [wallet]);

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, []);

  console.log("nft", products?.data?.slice(0, 3));
  return (
    <div
      onClick={handleClick}
      style={{
        boxShadow: "0 0 50px rgba(254,124,4,1)",
      }}
      className="lg:mt-0 mt-4 lg:w-[30%] w-full lg:order-2 order-3 cursor-pointer bg-gradient-to-b border border-[#666666] md:px-8 sm:px-6 px-5 md:py-7 py-4 sm:py-3 py-[10px] relative from-[#FF5500] to-[#070707] rounded-3xl lg:h-[100%] md:h-[50%] sm:h-[280px] h-[220px]"
    >
      <LinkButton
        className={
          "absolute 2xl:right-10 2xl:top-10 lg:right-5 md:right-10 sm:right-5 right-3 lg:top-4 md:top-8 sm:top-5 top-3"
        }
      />
      <div className="flex font-akira 2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl  sm:text-base text-[14px] font-akira font-bold uppercase flex-col items-start leading-[1.1]">
        <div className="flex   leading-[1.15] lg:flex-col flex-row lg:gap-x-0 gap-x-2 items-start">
          <h1>NFT</h1>
          <h1>Market Place</h1>
        </div>
        <div className="flex w-full justify-center md:mt-2 sm:mt-4 mt-2 items-center h-fit lg:gap-x-4 md:gap-x-6 sm:gap-x-4 gap-x-2">
          {products?.data?.slice(0, 3).map((item, index) => {
            return (
              <NftWrapper
                key={index}
                index={index}
                img={item.img}
                title={item.title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NftMarketCard;
