import React, { useEffect, useState } from "react";
import CardLayout from "../components/layout/CardLayout";
import ReferEarnHistoryCard from "../components/referEarn/ReferEarnHistoryCard";
import { useNavigate } from "react-router-dom";
import noTxn from "../assets/icon 3-01.png";
import { useReferredList } from "../hooks/useReferredList";
import ClientPaginationCard from "../components/common/ClientPaginationCard";
const CustomHeader = () => {
  return (
    <ul className="flex justify-between items-center font-redHat font-bold py-3 leading-[1.2] bg-[#000] rounded-full border border-[#666666] sm:text-base text-xs 2xl:px-[50px] md:px-10 sm:px-6 px-2">
      <li>Date</li>
      <li>Username</li>
      <li>Points Earned</li>
    </ul>
  );
};
const ReferralHistory = () => {
  const { data, error, loading } = useReferredList();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const handleNextPage = () => {
    if (totalPages > 1) {
      setCurrentPage((x) => x + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage >= 2) {
      setCurrentPage((x) => x - 1);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <CardLayout
      className="2xl:h-[740px] sm:h-[680px] h-[660px] lg:px-[60px] md:px-8 sm:px-6 px-4 pt-8 w-full rounded-[30px]"
      handleBack={handleBack}
      title={"Referral history"}
    >
      <div className="w-full mt-4 relative min-h-[460px]">
        <CustomHeader />
        <div className="2xl:h-[540px] h-[500px] overflow-y-auto relative mt-2 overflow-y-auto flex flex-col gap-y-2 w-full">
          {data?.totalReferred === 0 ? (
            <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 ">
              <img src={noTxn} alt="" className="relative left-10 mb-4" />
              <h1 className="font-akira text-center lg:text-3xl md:text-2xl sm:text-xl text-[14px]">
                No Referred Users
              </h1>
            </div>
          ) : (
            <></>
          )}
          {data?.referredUsers
            ?.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )
            ?.map((history, index) => {
              return (
                <ReferEarnHistoryCard
                  date={history?.referDate}
                  username={history?.username}
                  pointsEarned={data?.totalPoints}
                />
              );
            })}
        </div>
        {data?.totalReferred !== 0 && (
          <>
            <div className="w-full  flex justify-center items-center">
              <ClientPaginationCard
                isActive={true}
                currentPage={currentPage}
                totalPages={totalPages}
                handleNextClick={handleNextPage}
                handlePrevClick={handlePrevPage}
              />
            </div>
          </>
        )}
      </div>
    </CardLayout>
  );
};

export default ReferralHistory;
