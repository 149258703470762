import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../hooks/useAuth";

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const path = location?.state?.redirectPath || "/dashboard";

  if (isAuthenticated) return <Navigate to={path} />;

  return <>{children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
