import React from "react";
import { FaRegUser } from "react-icons/fa";
const NavUserButton = ({ handleClick, username }) => {
  return (
    <div
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      onClick={handleClick}
      className={` rounded-full cursor-pointer font-bold bg-radial-play text-white sm:px-4 px-2 py-2 font-redHat flex items-center gap-x-1  lg:text-xs text-[10px] h-fit transition duration-300 ease-in-out`}
    >
      <FaRegUser className="lg:text-xl text-base" />
      <span className="">{username}</span>
    </div>
  );
};

export default NavUserButton;
