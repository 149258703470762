import React from "react";

const Tile = ({ flipped, image, id, handleFlip, gameStarted }) => {
  return (
    <div
      onClick={handleFlip}
      className={` ${
        flipped
          ? `flipped ${gameStarted && "shadow-[0_0_20px_rgba(0,255,08,0.75)]"}`
          : ""
      } w-full 2xl:h-[240px] xl:h-[190px] cursor-pointer lg:h-[220px] md:h-[180px] h-[150px] rounded-[20px] bg-black flex items-center justify-center hover:scale-[.90] transition-all ease duration-[400ms] border border-[rgba(249, 249, 249, 1)] `}
      id={id}
    >
      <img
        src={image}
        alt="Memory Game Tile"
        className={`front xl:h-[120px] lg:h-[140px] md:h-[100px] h-[80px] xl:w-[120px] lg:w-[100px] lg:w-[120px] md:w-[90px] w-[75px]`}
      />

      <h1 className={`back  text-8xl font-bold font-akira`}>?</h1>
    </div>
  );
};

export default Tile;
