import React from "react";
import noTxn from "../../assets/icon 3-01.png";
const CardButton = ({ quantity, handleQuantityChange, productId }) => {
  return (
    <div className="flex rounded-md leading-[.8]   text-[10px] px-3 border border-[#999999] items-center gap-x-2">
      <span
        onClick={() => handleQuantityChange(productId, -1)}
        className="text-base cursor-pointer hover:opacity-75 mt-1 active:scale-[0.8] transition-all ease duration-150"
      >
        -
      </span>
      <span className="mt-1 leading-[1.2]">QTY : {quantity}</span>
      <span
        onClick={() => handleQuantityChange(productId, 1)}
        className="text-xl mt-1 cursor-pointer hover:opacity-75 active:scale-[0.8] transition-all ease duration-150 font-bold"
      >
        +
      </span>
    </div>
  );
};
const Card = ({
  logoURI,
  title,
  price,
  earning,
  quantity,
  handleQuantityChange,
  productId,
}) => {
  return (
    <div className="flex justify-between leading-[1.2] items-center w-full">
      <div className="flex items-center gap-x-4">
        <div className="2xl:h-24 h-20 2xl:w-24 flex items-center justify-center w-20 2xl:p-4 p-2 bg-[#6DD0E7] shadow-[0_2px_20px_rgba(109,2089,231,0.6)] rounded-xl">
          <img src={logoURI} alt="" className="h-fit w-fit rounded-lg" />
        </div>
        <div className="flex flex-col items-start">
          <h1 className="font-bold front-akira uppercase max-w-48 leading-[1.0] 2xl:text-[15px] text-[13px]">
            {title}
          </h1>
          <h2 className="text-[11px] font-redHat sm:mt-2 mt-1">Blazprice</h2>
          <span className="2xl:text-[13px] sm:text-xs text-[10px] font-bold text-[#ff3503]">
            {price} BLZ
          </span>
          <p className="sm:text-[10px] text-[7px]">Creator {quantity}%</p>
        </div>
      </div>
      <div className="flex flex-col gap-y-1 items-end">
        <CardButton
          handleQuantityChange={handleQuantityChange}
          productId={productId}
          quantity={quantity}
        />
        <h1 className="text-[13px] font-bold uppercase">
          {price * quantity} BLZ
        </h1>
      </div>
    </div>
  );
};
const NftCartWrapper = ({ data, handleClearCart, handleQuantityChange }) => {
  return (
    <div className="2xl:h-[420px] h-[320px] flex flex-col 2xl:gap-y-4 gap-y-2 w-full 2xl:my-2 border-t border-b border-[#666666]">
      {data?.length !== 0 && (
        <>
          <div className="flex  px-4 font-redHat text-base items-center mt-4 justify-between">
            <h1>Items</h1>
            <h1
              onClick={handleClearCart}
              typeof="button"
              className="cursor-pointer hover:opacity-75"
            >
              Clear All
            </h1>
          </div>
        </>
      )}
      <div className="2xl:h-[86%] h-[90%] w-full flex flex-col 2xl:gap-y-5 gap-y-3 2xl:py-3 py-1 overflow-y-auto">
        {data?.length === 0 && (
          <div className="h-full w-full flex flex-col text-base items-center justify-center">
            <img src={noTxn} className="h-fit w-fit scale-90" alt="" />
            <h1 className="font-akira">No NFTs!</h1>
          </div>
        )}
        {data?.map((item, index) => {
          return (
            <Card
              key={item?.productId}
              logoURI={item?.productImage}
              title={item?.productName}
              quantity={item?.quantity}
              price={item?.productPrice}
              handleQuantityChange={handleQuantityChange}
              productId={item?.productId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NftCartWrapper;
