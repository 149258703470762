import React from "react";
import { MdCallReceived } from "react-icons/md";
import { MdOutlineCallMade } from "react-icons/md";
const TransactionCard = ({
  chain,
  logoURI,
  mode,
  module,
  _date,
  pointsEarned,
}) => {
  const formatDate = (d) => {
    const date = new Date(d);
    const res = date.toLocaleDateString("en-GB").split("/").reverse().join("-");
    return res;
  };
  return (
    <div className="flex justify-between w-full group items-center sm:text-base text-[10px] font-redHat font-light py-2 leading-[1.2] rounded-full cursor-pointer transition-all duration-100 ease border border-[rgba(255,255,255,0.16)] hover:border-[rgba(255,255,255,0.5)] 2xl:px-[50px] md:px-10 sm:px-6 px-2">
      <div className="sm:w-full">
        {mode === "in" ? (
          <MdCallReceived className="text-xl group-hover:scale-[1.35] transition-all duration-[400ms] ease" />
        ) : (
          <MdOutlineCallMade className="text-xl" />
        )}
      </div>
      <div className="sm:w-full items-center flex sm:gap-x-3 gap-x-1">
        <img
          src={logoURI}
          className="2xl:h-7 h-5 2xl:w-7 w-5 rounded-full"
          alt=""
        />
        <p>{chain}</p>
      </div>
      <div className="sm:w-full text-center capitalize">{module}</div>
      <div className="sm:w-full text-end">{formatDate(_date)}</div>
      <div className="sm:w-full text-end">{pointsEarned}</div>
    </div>
  );
};

export default TransactionCard;
