import "../../assets/css/slotMachine.css";
import jackpotMachine from "../../assets/jackpot_machine.png";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { handleTransactionError, toastPromise } from "../../services/util";
import { parseEther } from "ethers/lib/utils";
import { useAuth } from "../../hooks/useAuth";
import { getSignature } from "../../api/games";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import axios from "axios";
import ButtonCard from "../../components/games/ButtonCard";
import LearnButton from "../../components/common/LearnButton";
import FilledButton from "../../components/games/FilledButton";
import JackpotButtonCard from "../../components/games/JackpotButtonCard";
const RowComponent = ({ ring, num, className, spinningRow }) => {
  const index = ring ? ring % num.length : 0;

  return (
    <div
      className={`${
        spinningRow
          ? "ringMoving 2xl:text-8xl sm:text-7xl text-6xl"
          : "2xl:text-8xl sm:text-7xl text-6xl font-bold"
      } w-16 ${className} h-full`}
    >
      <div className="text-black ringEnd  flex justify-center items-center">
        {num[index]}
      </div>
      <div className="text-black ringEnd flex justify-center items-center">
        {num[(index + 1) % num.length]}
      </div>
      <div className="text-black ringEnd flex justify-center items-center">
        {num[(index + 2) % num.length]}
      </div>
      <div className="text-black ringEnd flex justify-center items-center">
        {num[(index + 3) % num.length]}
      </div>
    </div>
  );
};

const SlotMachine = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRolling, setIsRolling] = useState(false);
  const [rollsLeft, setRollsLeft] = useState(10);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const [ring1, setRing1] = useState();
  const [ring2, setRing2] = useState();
  const [ring3, setRing3] = useState();
  const [spinningRow1, setSpinningRow1] = useState(false);
  const [spinningRow2, setSpinningRow2] = useState(false);
  const [spinningRow3, setSpinningRow3] = useState(false);
  const [num1] = useState([1, 2, 5, 6]);
  const [num2] = useState([3, 4, 5, 6]);
  const [num3] = useState([1, 2, 3, 4]);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [totalRollsDone, setTotalRollsDone] = useState(0);
  const [totalRollRewards, setTotalRollsReward] = useState(0);

  const wallet = useWallet();
  const auth = useAuth();

  const handleSubmit = async () => {
    if (totalRollsDone <= 0) {
      toast.info("You haven't rolled yet!");
      return;
    }

    const config = wallet.getChainConfig();
    if (!config) return;

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      const transactionOptions = {
        value: parseEther(
          String((config.features.spinValue * totalRollsDone).toFixed(8))
        ),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const message = "Slot Machine";
      const nonce = 168;

      const signature = await getSignature({
        to: wallet.address,
        amount: ethers.utils.parseEther(currentEarnings.toString()),
        message: message,
        nonce: nonce,
        chainId: wallet.chainId,
      });

      const [, tx] = await wallet.safeCallContract({
        name: "Game",
        method: "bulkRoll",
        args: [
          totalRollsDone,
          ethers.utils.parseEther(currentEarnings.toString()),
          1,
          message,
          nonce,
          signature,
          transactionOptions,
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Submitting...",
          success: () => {
            setCurrentEarnings(0);
            setTotalRollsDone(0);
            setTotalRollsReward(+totalRollRewards + currentEarnings);
            wallet.updatePoints({
              amount: currentEarnings,
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              moduleName: "jackpot",
              txHash: tx.hash,
            });
            auth.updateTokens();
            return "Submitted successfully!";
          },
          error: (err) => {
            handleTransactionError(err);
          },
        });
      }

      setIsSubmitting(false);
    }
  };

  const roll = () => {
    if (rollsLeft <= 0) {
      toast.error("No rolls left");
      return;
    }
    setIsRolling(true);
    setSpinningRow1(true);
    setSpinningRow2(true);
    setSpinningRow3(true);
    const winnings = Math.floor(Math.random() * 100);
    const newRing1 = Math.floor(Math.random() * num1.length);
    const newRing2 = Math.floor(Math.random() * num2.length);
    const newRing3 = Math.floor(Math.random() * num3.length);

    setTimeout(() => {
      setSpinningRow1(false);
      setRing1(newRing1);
    }, 500 + Math.random() * 500);

    setTimeout(() => {
      setSpinningRow2(false);
      setRing2(newRing2);
    }, 1000 + Math.random() * 500);

    setTimeout(() => {
      setSpinningRow3(false);
      setIsRolling(false);
      setRing3(newRing3);
      setCurrentEarnings(currentEarnings + winnings);
      setTotalRollsDone(totalRollsDone + 1);
      setRollsLeft((x) => x - 1);
    }, 1500 + Math.random() * 500);
  };

  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "JACKPOTROLL",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setTotalRollsDone(count);
        setRollsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    setIsSubmitting(true);
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(amount);
        // updateActivityCount();
        getActivityCount();
        setCurrentEarnings(0);
        setTotalRollsDone(0);
        setTotalRollsReward(+totalRollRewards + amount);
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "jackpot",
          txHash: "lightlink",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "JACKPOTROLL",
          date: new Date(),
          count: originalrollsLeft - rollsLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "JACKPOTROLL" },
        "GET"
      );
      if (isSuccess) {
        setTotalRollsReward(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    const currentCount = await getActivityCount();
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "JACKPOTROLL",
          date: new Date(),
          count: currentCount + 1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  const init = async () => {
    // setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getRollsLeft",
        args: [wallet.address],
      });
      console.log("res", left?.toNumber());
      if (left) {
        setRollsLeft(left.toNumber());
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getRollRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRollsReward(
          Number(
            ethers.utils.formatUnits(rewards.toString()).toString()
          ).toFixed(0)
        );
      }
    }

    // setIsLoading(true);
  };
  // init().finally(() => setIsLoading(false));
  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet.address, wallet.chainId]);
  if (isLoading) return <FullScreenLoader />;

  return (
    <div className="w-full md:pt-28 pt-[84px]  min-h-screen">
      <div className="container 2xl:pb-0 pb-8 xl:px-5 px-8 mx-auto">
        <div className="flex w-full  md:flex-row flex-col items-center justify-between items-center">
          <h1 className=" 2xl:px-16 2xl:text-7xl lg:text-6xl xl:text-left text-center md:w-fit w-full sm:text-4xl text-3xl font-bold font-akira">
            Jackpot
          </h1>
          <LearnButton
            title={"Learn to Play"}
            className={"xl:hidden block md:mt-0 mt-3"}
            redirect={
              "https://medium.com/@blazpay/spin-the-slots-test-your-luck-and-win-big-rewards-with-the-blazpay-jackpot-game-0cad2422e403"
            }
          />
        </div>

        <div className="flex xl:flex-row xl:gap-y-0 gap-y-8 flex-col xl:mt-12 sm:mt-8 mt-4 w-full mx-auto">
          <div className="xl:w-1/2  w-full flex  flex-col">
            <div className="relative  w-full flex flex-col items-center">
              <img
                src={jackpotMachine}
                alt=""
                className="2xl:h-[600px] relative xl:left-0 left-5 sm:h-[500px] h-[400px]  2xl:w-[500px] sm:w-[400px] w-[300px]"
              />
              <div className="flex gap-x-4 w-[400px] overflow-hidden 2xl:h-[100px] sm:h-[80px] h-[60px]  text-black absolute 2xl:left-52 xl:left-44 left-1/2 xl:-translate-x-0 -translate-x-1/2 2xl:top-36  sm:top-[120px] top-24">
                <RowComponent
                  spinningRow={spinningRow1}
                  ring={ring1}
                  num={num1}
                  className={"relative 2xl:left-2 xl:left-0 sm:left-20 left-28"}
                />
                <RowComponent
                  spinningRow={spinningRow2}
                  ring={ring2}
                  num={num2}
                  className={
                    "relative 2xl:left-6 xl:left-0 sm:left-20 left-[90px]"
                  }
                />
                <RowComponent
                  spinningRow={spinningRow3}
                  ring={ring3}
                  num={num3}
                  className={
                    "relative 2xl:left-12 xl:left-0 sm:left-20 left-[72px]"
                  }
                />
              </div>
              {rollsLeft > 0 ? (
                <div className="flex sm:flex-row flex-col sm:gap-y-0 gap-y-3 relative 2xl:mt-5 sm:mt-2 mt-4 xl:-left-8 sm:gap-x-10 items-center">
                  <ButtonCard text={"Rolls Left"} value={rollsLeft} />
                  <FilledButton
                    handleClick={roll}
                    text={isRolling ? "Rolling..." : "Roll"}
                    isLoading={isRolling}
                    loadintText={"Rolling..."}
                  />
                </div>
              ) : (
                <div
                  style={{
                    boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
                  }}
                  className=" px-7 relative -left-8 w-fit flex items-center justify-center py-1 bg-black rounded-full border-2 border-primary"
                >
                  <h1 className="font-redHat uppercase text-base">
                    No More Rolls Left! Access Tomorrow
                  </h1>
                </div>
              )}
            </div>
          </div>
          <div className="xl:w-1/2 w-full flex flex-col gap-y-5  items-center justify-center">
            <LearnButton
              title={"Learn to Play"}
              className={"xl:block hidden"}
              redirect={
                "https://medium.com/@blazpay/spin-the-slots-test-your-luck-and-win-big-rewards-with-the-blazpay-jackpot-game-0cad2422e403"
              }
            />
            <div className="flex w-full justify-center sm:gap-x-10  gap-x-4">
              <JackpotButtonCard
                text="Current Roll Rewards"
                value={currentEarnings}
              />
              <JackpotButtonCard
                text="Total Roll Rewards"
                value={totalRollRewards}
              />
            </div>
            <FilledButton
              handleClick={handleSubmit}
              text={isSubmitting ? "Submitting..." : "Submit"}
              isLoading={isSubmitting}
              loadintText={"Submitting..."}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotMachine;
