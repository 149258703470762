import axios from "axios";
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
export const getProducts = (params) => {
  return axios.get("/api/product", { params: {} });
};

export const getCart = async (params) => {
  return axios.get("/api/cart", { params }).catch((err) => {
    if (err.response?.status === 404)
      return {
        items: [],
      };
    throw err;
  });
};

export const addToCart = async (payload) => {
  return axios
    .post("/api/cart", {
      productId: payload?.productId,
      quantity: payload?.quantity,
      chainId: payload?.chainId,
    })
    .then((data) => {
      if (payload?.showToast) toast.success("Added to the cart");
      return data;
    })
    .catch((err) => {
      console.error(err);
      if (payload?.showToast) {
        toast.error("Error occurred while adding to the cart");
      }
    });
};
export const completePurchase = async (data) => {
  return axios.post("/api/cart/complete-purchase", data).catch(console.error);
};
