import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { useWallet } from "../hooks/useWallet";
import { NFTRequiredError } from "../services/error";
import FilledButton from "../components/games/FilledButton";
import leaderboardLogo from "../assets/home_leaderboard.svg";
import dailyTaskLogo from "../assets/home_daily.svg";
import HomeLeaderboardCard from "../components/home/HomeLeaderboardCard";
import HomeDailyQuestCard from "../components/home/HomeDailyQuestCard";
import CommonCard from "../components/home/CommonCard";
import stakeLogo from "../assets/home_stake.svg";
import entrypassLogo from "../assets/home_entrypass.svg";
import QuizCard from "../components/home/QuizCard";
import quizLogo from "../assets/home_quiz.svg";
import PlayEarnCard from "../components/home/PlayEarnCard";
import playEarnLogo from "../assets/home_play_earn.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import videoUrl from "../assets/video/landingVideo.mp4";
import { EffectCards, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css";
const chainId = 1;
const cards = [
  {
    id: 1,
    element: (
      <HomeLeaderboardCard
        title={"Leaderboard"}
        className={" h-[360px]"}
        logoURI={leaderboardLogo}
      />
    ),
  },
  {
    id: 2,
    element: (
      <CommonCard
        title={"Staking Platform"}
        className={" h-[360px] "}
        logoURI={stakeLogo}
        module={1}
      />
    ),
  },

  {
    id: 3,
    element: (
      <PlayEarnCard
        title={"Play & Earn"}
        className={"h-[360px]"}
        logoURI={playEarnLogo}
      />
    ),
  },
  {
    id: 4,
    element: (
      <QuizCard title={"Quiz"} className={"h-[360px]"} logoURI={quizLogo} />
    ),
  },
  {
    id: 5,
    element: (
      <CommonCard
        title={"Entrypass System"}
        className={" h-[360px] "}
        logoURI={entrypassLogo}
        module={0}
      />
    ),
  },

  {
    id: 6,
    element: (
      <HomeDailyQuestCard
        title={"Daily Quest"}
        className={" h-[360px] "}
        logoURI={dailyTaskLogo}
      />
    ),
  },
];

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showEntrypass, setShowEntrypass] = useState(false);
  const [effectTriggeredBy, setEffectTriggeredBy] = useState(null);
  const auth = useAuth();
  const { isConnected, address } = useWallet();

  const { open } = useWeb3Modal();

  const signMessage = async () => {
    try {
      setIsLoading(true);

      await auth.login({ address, chainId });
    } catch (err) {
      if (err instanceof NFTRequiredError) {
        setShowEntrypass(true);
      } else if (err.code === 4001) {
        toast.error("You have rejected the transaction");
      } else if (err.request || err.response) {
        console.error(err);
        toast.error("API server is down, please try again later");
      } else {
        console.error(err);
        toast.error("An unexpected error occurred, please contact admin");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;
    if (isConnected) {
      signMessage();
    } else {
      setEffectTriggeredBy("connect");
      open({ view: "Connect" });
    }
  };

  useEffect(() => {
    if (effectTriggeredBy === null || !isConnected) return;
    signMessage();
  }, [isConnected, effectTriggeredBy]);

  return (
    <div className="min-h-screen z-1 overflow-hidden lg:pb-4 xl:px-0 sm:pt-16 pt-12 text-white w-full">
      <video
        src={videoUrl}
        className="h-full w-full lg:block hidden object-cover absolute top-1/2 -translate-y-1/2  left-0"
        autoPlay={true}
        loop
        muted
      ></video>
      <div className="xl:container xl:px-5 px-8  relative w-full flex flex-col items-center mx-auto">
        <h1 className="2xl:text-[60px] xl:text-5xl text-4xl  flex flex-col 2xl:gap-y-2 gap-y-1 items-center  text-center font-akira font-bold">
          <span>Unlock</span>
          <span>Rewards With</span>
          <span>Blazpay</span>
        </h1>
        <p className="md:text-xl sm:text-base text-[13px] text-center mt-4 max-w-[500px]">
          Participate in Weekly USD Contests and win Blazpoints too on Our
          Dashboard
        </p>

        <FilledButton
          text={
            isLoading
              ? "Loading..."
              : isConnected
              ? "Sign In"
              : "Connect Wallet"
          }
          isLoading={isLoading}
          loadintText={
            isLoading
              ? "Loading..."
              : isConnected
              ? "Sign In"
              : "Connect Wallet"
          }
          handleClick={handleSubmit}
          className={
            "2xl:mt-8 sm:mt-4 relative sm:top-0 bottom-8 top-[460px] bg-black"
          }
        />
      </div>
      <div className="xl:container hidden   mx-auto 2xl:mt-8 mt-5  2xl:h-[480px] xl:h-[380px] h-[320px] lg:flex hidden  items-center xl:gap-x-4 gap-x-2">
        <HomeLeaderboardCard
          title={"Leaderboard"}
          className={"xl:h-[56%] h-[60%] bg-black"}
          logoURI={leaderboardLogo}
        />
        <CommonCard
          title={"Staking Platform"}
          className={"xl:h-[80%] h-[85%] "}
          logoURI={stakeLogo}
          module={1}
        />
        <div className="flex  flex-col xl:gap-y-4 gap-y-2 items-center justify-center  h-full w-full">
          <PlayEarnCard
            title={"Play & Earn"}
            className={"xl:h-[50%] h-[56%]"}
            logoURI={playEarnLogo}
          />
          <QuizCard title={"Quiz"} className={"h-[40%]"} logoURI={quizLogo} />
        </div>
        <CommonCard
          title={"Entrypass System"}
          className={"xl:h-[80%] h-[85%] "}
          logoURI={entrypassLogo}
          module={0}
        />
        <HomeDailyQuestCard
          title={"Daily Quest"}
          className={"xl:h-[56%] h-[60%] "}
          logoURI={dailyTaskLogo}
        />
      </div>
      <div className="w-full relative lg:container h-[360px] lg:hidden block mx-auto 0 2xl:mt-12 xl:mt-6 lg:mt-8 sm:mt-6 mt-0">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          modules={[EffectCards, Navigation, Pagination]}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          }}
          onSlideChange={(swiper) =>
            console.log("Slide index changed:", swiper.activeIndex)
          }
        >
          {cards?.map((card, index) => (
            <SwiperSlide key={card?.id}>
              <div className="flex h-full md:pt-10 pt-8  pb-2 justify-center">
                <>{card?.element}</>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="h-[104px] sm:hidden  block"></div>
    </div>
  );
};

export default Home;
