const WinnerCard = ({
  className,
  imgSrc,
  winner,
  _bottom,
  _shadow,
  _fontSize,
}) => {
  return (
    <div className={`${className} relative`}>
      <img src={imgSrc} alt={imgSrc} className={`h-fit w-fit`} />

      <h1
        style={{ bottom: `${_bottom}px`, fontSize: `${_fontSize}px` }}
        className={`text-black font-bold hover:scale-[1.25]
           capitalize transition-all ease duration-[600ms] 
           cursor-pointer  absolute font-redHat  left-1/2 -translate-x-1/2`}
      >
        {winner}
      </h1>
    </div>
  );
};

export default WinnerCard;
