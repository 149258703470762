import React from "react";

const LearnButton = ({ title, className, redirect }) => {
  const handleClick = () => {
    window.open(redirect, "_blank");
  };
  return (
    <button
      onClick={handleClick}
      style={{
        boxShadow:
          "0 6px 12px -8px #fff inset,0 -12px 15px 0px rgba(255,255,255,0.4) inset, 0 0 10px rgba(255,255,255,0.3) inset",
      }}
      className={`${className} rounded-full cursor-pointer hover:opacity-75 active:scale-[.95] font-bold bg-radial-play text-white  sm:px-12 px-6  lg:py-3 py-2 font-redHat  sm:text-base text-xs h-fit transition duration-300 ease-in-out`}
    >
      {title}
    </button>
  );
};

export default LearnButton;
