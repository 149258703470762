import React from "react";
import BottomCard from "./BottomCard";
import Tilt from "react-parallax-tilt";
const HomeLeaderboardCard = ({ title, className, logoURI }) => {
  return (
    <Tilt
      className={`${className} z-50 bg-black relative group flex overflow-hidden relative flex-col items-center
     lg:justify-start justify-center lg:shadow-[0_0_30px_rgba(243,181,61,1)] shadow-[0_0_40px_rgba(255,81,0,0.7)] w-full xl:px-7 lg:px-5 px-3 xl:py-6 lg:py-4 lg:py-8 py-6 lg:rounded-[22px] rounded-3xl md:border-[0.5px] border-[1px]`}
      tiltMaxAngleX={20}
      tiltMaxAngleY={20}
      perspective={800}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <div>
        <h1 className="lg:text-base lg:block hidden  font-akira uppercase font-bold">
          {title}
        </h1>
        <img
          src={logoURI}
          className="h-fit w-fit 2xl:mt-16 lg:mt-10  group-hover:scale-90 transtion-all duration-[250ms] ease"
          alt={logoURI}
        />
        <BottomCard title={title} />
      </div>
    </Tilt>
  );
};
export default HomeLeaderboardCard;
