import React, { act } from "react";
import blazPointsLogoURI from "../../assets/blaz_points.png";
import dragonWarrior from "../../assets/dragon_Warrior.png";
import referralLogo from "../../assets/referral_logo.svg";
const UserCard = ({
  rank,
  points,
  name,
  active = false,
  module = "leaderboard",
}) => {
  return (
    <div
      className={`flex relative  2xl:rounded-3xl rounded-[20px] cursor-pointer items-center justify-start  ${
        active
          ? "md:gap-x-4 gap-x-2 px-6 border-white bg-gradient-to-r shadow-[0_0_50px_rgba(255,155,45,0.9)]"
          : "md:gap-x-12 sm:gap-x-8 gap-x-2 md:px-12 sm:px-6 px-3"
      }  2xl:py-4 py-2 
       hover:border-white bg-[#141419] hover:bg-gradient-to-r from-[#ff9b2d] to-[#ff4719]
       border-[#666666] border hover:shadow-[0_0_50px_rgba(255,155,45,0.9)] group `}
    >
      {active ? (
        <h1
          className={`${
            active ? "text-black" : "text-white"
          }  group-hover:text-black md:text-base text-xs font-redHat font-bold`}
        >
          {rank}
        </h1>
      ) : (
        <div
          style={{
            boxShadow: "0 0 10px rgba(0,0,0,0.5)",
          }}
          className={`md:text-base sm:text-[13px] text-[10px] 2xl:h-10 2xl:w-10 md:h-[30px] h-6  md:w-[30px] w-6 flex items-center justify-center rounded-full md:border-[3px] border-2 border-white
             group-hover:bg-[#ff9b2d] bg-[#047DA4]
      `}
        >
          <span>{rank}</span>
        </div>
      )}
      <img
        src={dragonWarrior}
        className='2xl:h-12 h-10 rounded-full'
        alt='userprofile'
      />
      <h1
        className={`capitalize 2xl:text-xl md:text-base text-[13px] text-[10px] font-bold ${
          active ? "text-black" : "text-white"
        }  group-hover:text-black font-redHat`}
      >
        {name}
      </h1>
      <div className='absolute top-1/2 -translate-y-1/2 xl:right-28 lg:right-20 md:right-16 right-4 transition-all duration-[500ms] ease-in-out md:group-hover:right-24 group-hover:right-11 rounded-full 2xl:h-11 md:h-9 h-7 bg-gradient-to-r from-black to-[#ff5500] md:w-32 w-28'>
        <div className='absolute  h-[calc(100%-2px)] left-[1px] top-[1px] w-[calc(100%-2px)] flex justify-between items-center px-5   bg-black rounded-full'>
          {module === "leaderboard" ? (
            <img
              src={blazPointsLogoURI}
              alt=''
              className='h-fit w-fit md:scale-100 scale-[.60]'
            />
          ) : (
            <img
              src={referralLogo}
              alt=''
              className='h-fit w-fit md:scale-100 scale-[.60]'
            />
          )}
          <span className='font-redHat font-bold 2xl:text-base md:text-[13px] text-[11px] text-white'>
            {points.toFixed(0)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
