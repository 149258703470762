import { IoIosArrowDown } from "react-icons/io";

const ChainCard = ({ chain, handleClick, isDropdownActive }) => {
  return (
    <div
      onClick={() => handleClick((x) => !x)}
      className="2xl:px-4  md:px-[10px] px-4 2xl:py-[5px] py-2 bg-black flex md:justify-center justify-between md:relative absolute
    md:-top-0 -top-16 md:left-0 left-1/2 -translate-x-1/2 md:-translate-x-0 gap-x-1
     items-center cursor-pointer rounded-full border border-primary"
    >
      {chain?.imgPath && (
        <img src={chain?.imgPath} alt="" className="h-4 w-4 rounded-full" />
      )}
      <h1 className="2xl:text-xl text-nowrap md:text-xs text-[14px]">
        {chain?.chainName} Chain
      </h1>
      <div className="md:px-0 px-2">
        <IoIosArrowDown
          className={`2xl:text-xl text-base ${
            isDropdownActive && "rotate-180"
          } transition-all duration-150 ease`}
        />
      </div>
    </div>
  );
};

export default ChainCard;
